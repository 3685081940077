import React from 'react';
import { ChakraProvider, cookieStorageManagerSSR, localStorageManager } from '@chakra-ui/react';

// This should become a parameter for 'withChakra'
export const chakraDefaultProps = {
    resetCSS: true,
    theme: require('./../../theme').default,
};

/**
 * Adds Chakra UI support to any element. Usually, page components are recommended to
 * be used for this type of functionality, to keep the added provider as high up in the
 * chain of elements as possible.
 * 
 * In addition, this function manages the 'flashing' that's caused by switching color
 * modes after loading an initial SSR page (with light theme due to SSR).
 * 
 * @param {JSX.Element} OriginalComponent   Any element that can be used in JSX.
 * @param {Object} chakraInitProps          Object with keys used internally as props for @type {ChakraProvider}.
 * 
 * @returns The input component, wrapped by @type {ChakraProvider}.
 */
export const withChakra = (element, isSsr, chakraInitProps) => {
    /**
     * TODO: This doesn't retrieve cookies (with user setting for gatsby-browser) yet. It
     *       also can't fix colors. With the default theme (disable 'theme' above) you'll
     *       see no flash, while custom components + custom theme does flash.
     * 
     *       Problem could be caused by `useColorModeValue` being used everywhere in components
     *       instead of up-front as part of a theme (e.g. causing a delay in loading its CSS?).
     * 
     * More info: https://chakra-ui.com/docs/styled-system/color-mode#add-colormodemanager-optional-for-ssr
     */
    const cookies = null;
    const colorModeManager = typeof cookies === 'string'
      ? cookieStorageManagerSSR(cookies)
      : localStorageManager;

    return (<>
        <ChakraProvider
            colorModeManager={isSsr ? colorModeManager : undefined}
            {...chakraDefaultProps}
            {...chakraInitProps}>
            {element}
        </ChakraProvider>
    </>);
};
