import React from 'react';
import PropTypes from 'prop-types';

import { merge } from 'lodash';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { BodySection, PastExperienceSection } from 'components/section/service';
import { usePastExperiences } from 'utils/hook/usePastExperiences';

import { PageHeader } from 'components/view';
import { DefaultLayout } from 'components/layout';
import { MetaData } from 'components/meta';

/**
 * Page template for (translatable) MDX files with details about services being offered.
 */
const Service = ({ children, location, data, pageContext }) => {
    /* eslint-disable react-hooks/exhaustive-deps */
    const { frontmatter } = pageContext;
    const { t } = useTranslation();

    // Combine experience items with data from raw MDX frontmatter and
    // data from the converted MDX frontmatter. It's the same, but the
    // latter has `childImageSharp` objects from the inferred (by Gatsby)
    // file objects via the YAML section in MDX files.
    const mergedExperienceItems = merge(frontmatter, data.mdx.frontmatter);
    const [experienceItems] = usePastExperiences(mergedExperienceItems.experience);

    return (<>
        <MetaData location={location} />
        <DefaultLayout>
            <PageHeader
                {...frontmatter}
                {...pageContext}
                description={t('banner_body')} />
            <BodySection
                {...frontmatter}>
                {children}
            </BodySection>
            <PastExperienceSection items={experienceItems} />
            {/* <PageFooter /> */}
        </DefaultLayout>
    </>)
};

Service.propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Service;
