import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { mode } from '@chakra-ui/theme-tools';

/**
 * You can also use the more specific type for
 * a single part component: ComponentSingleStyleConfig
 * 
 * See: https://chakra-ui.com/docs/styled-system/theming/component-style
 */
const Container: ComponentStyleConfig = {
    baseStyle: {
    },
};

export default Container;
