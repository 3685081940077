import React from 'react';
import { Input, InputLeftElement, InputGroup, Button as ChakraButton, useColorModeValue } from '@chakra-ui/react';

import { useField } from 'formik';
import { Icon } from 'components/view';

const GroupedInputArea = ({ label, content, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field/*, meta*/] = useField(props);
    return (
        <InputGroup mb={3}>
            <InputLeftElement
                pointerEvents="none"
                m=".25em"
                children={<Icon
                    name="bx-envelope"
                    size="xs"
                    color={useColorModeValue('gray.300', 'whiteAlpha.400')} />}
            />
            <Input
                size="lg"
                bg={useColorModeValue('gray.25', 'blackAlpha.400')}
                borderColor={useColorModeValue('gray.100', 'blackAlpha.500')}
                borderEnd="none"
                roundedEnd="none"
                rounded="lg"
                fontSize="md"
                _hover={{
                    borderColor: useColorModeValue('primary.200', 'primary.300'),
                }}
                _placeholder={{
                    color: useColorModeValue('gray.300', 'gray.700'),
                    fontSize: 'md',
                }}
                {...field}
                {...props} />
            <ChakraButton
                roundedStart="none"
                rounded="lg"
                type="submit">
                {content}
            </ChakraButton>
        </InputGroup>
    );
};

export default GroupedInputArea;
