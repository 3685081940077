import React from 'react';
import { Stack } from '@chakra-ui/react';

/**
 * Pre-built component for a Hero type of layout with optional space for an image or button(s).
 */
const HeroLayout = ({ align, descriptionComponent, callToActionComponent, imageComponent, ...rest }) => {
    return (<Stack
        align={align || 'start'}
        spacing={{ base: 6, md: 8 }}
        py={6}
        direction={{ base: 'column', md: 'row' }}
        {...rest}>
        <Stack flex={1} align={{ base: 'center', md: 'start' }} spacing={{ base: 8, md: 12 }}>
            {descriptionComponent}
            <Stack
                gap={{ base: 4, sm: 6 }}
                direction={{ base: 'column', sm: 'row' }}>
                {callToActionComponent}
            </Stack>
        </Stack>
        {imageComponent}
    </Stack>);
};

export default HeroLayout;
