import React, { Children, useRef } from 'react';
import { motion } from 'framer-motion';
import { Flex, IconButton, useBreakpointValue } from '@chakra-ui/react';

import { Icon } from 'components/view';
import { ScrollContainer } from 'components/layout';

const ScrollerItemComponent = ({ child, ...rest }) => {
    return <Flex
        as={motion.div}
        wrap="wrap"
        alignItems="stretch"
        alignContent="stretch"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        {...rest}>
        {child}
    </Flex>
}

const ScrollerBody = ({ children, ...rest }) => {
    return (<Flex
        {...rest}>
        {children}
    </Flex>);
}

const SlideScroller = ({ showButtons, gap, children, scrollbarPaddingBottom, ...rest }) => {
    const childArray = Children.toArray(children);

    const scrollBy = 160;
    const scrollerRef = useRef();

    const handleScrollPrevious = () => scrollerRef.current.scrollBy({ left: -scrollBy, behavior: 'smooth' });
    const handleScrollNext = () => scrollerRef.current.scrollBy({ left: scrollBy, behavior: 'smooth' });

    let showIconButtons = useBreakpointValue({ base: false, md: true });
    const sharedIconButtonProps = {
        isRound: true,
        rounded: 'full',
        variant: 'ghost',
        display: 'flex',
        transition: "all .4s ease-out",
        marginBottom: (scrollbarPaddingBottom * 2) || 3,
    };

    // Allow consumer of this component to make a custom choice
    if (showButtons !== undefined) {
        showIconButtons = showButtons;
    }

    // IconButton will lose its display=flex attribute after hot reload - unfortunately
    // this is a known and annoying issue, but doesn't appear to affect production builds.
    return (<Flex
        direction="row"
        align="center"
        justifyContent={showIconButtons ? 'space-between' : 'space-around'}
        gap={{ base: 1, md: 3 }}>
        {showIconButtons && <IconButton
            {...sharedIconButtonProps}
            icon={<Icon name="bx-chevron-left" />}
            onClick={handleScrollPrevious}
            aria-label="Previous Item"
        />}

        <ScrollContainer
            ref={scrollerRef}
            pb={scrollbarPaddingBottom || 3}>
            <ScrollerBody gap={gap || 3} {...rest}>
                {Children.map(childArray, (child, index) => {
                    return <ScrollerItemComponent
                        key={index}
                        child={child}
                        scrollSnapAlign="center"
                    />;
                })}
            </ScrollerBody>
        </ScrollContainer>

        {showIconButtons && <IconButton
            {...sharedIconButtonProps}
            icon={<Icon name="bx-chevron-right" />}
            onClick={handleScrollNext}
            aria-label="Next Item"
        />}
    </Flex>);
};

export default SlideScroller;
