import React, { Children, useState, useEffect } from 'react';
import { Box, Circle, Flex, HStack, Button, useColorModeValue } from '@chakra-ui/react';

import { GridStack } from 'components/layout';

/**
 * Standard carousel control with clickable dots for user navigation.
 */
const Carousel = ({ children, timeout, ...rest }) => {
    /* eslint-disable react-hooks/exhaustive-deps */
    const childArray = Children.toArray(children);
    const [selectedChild, setSelectedChild] = useState(0);

    // Sets up an update timer - won't be called each update due to usage of [] argument
    const childArrayLength = childArray.length;
    useEffect(() => {
        const id = setInterval(() => {
            // Explicitly use functional update form of 'setState'
            setSelectedChild(c => (c + 1) % childArrayLength);
        }, timeout);
        return () => clearInterval(id);
    }, [childArrayLength]);

    const activeDotColor = useColorModeValue('primary.300', 'white');
    const inactiveDotColor = useColorModeValue('blackAlpha.100', 'whiteAlpha.100');
    const navDotComponents = Array.from({ length: childArray.length }, (_, id) =>
        <Button
            key={id}
            display="flex"
            variant="unstyled"
            minWidth={8}
            onClick={() => setSelectedChild(id)}>
            <Circle
                size="2"
                bg={`${id === selectedChild ?
                    activeDotColor :
                    inactiveDotColor}`} />
        </Button>
    );

    return (<Box
        flexDirection="column"
        {...rest}>
        <GridStack
            as={Flex}
            direction="column"
            align="stretch">
            {Children.map(childArray, (child, id) => {
                // Use visibility to keep height of largest component
                // without doing magic to calculate boundings.
                return <GridStack.Item
                    key={id}
                    visibility={selectedChild === id ? 'visible' : 'hidden'}>
                    {child}
                    {/* Disabled because it waits one or two seconds on mobile and looks less professional */}
                    {/* <SlideFade
                        unmountOnExit={false}
                        in={selectedChild === id}
                        offsetY="20px"
                        >
                        {child}
                    </SlideFade> */}
                </GridStack.Item>;
            })}
        </GridStack>
        <HStack
            justify="center"
            spacing={0}
            mt={3}
            color={useColorModeValue('gray.100', 'gray.700')}>
            {navDotComponents}
        </HStack>
    </Box>);
};

export default Carousel;
