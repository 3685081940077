import React from 'react';

import { Flex, useColorModeValue, useTheme } from '@chakra-ui/react';
import { getColor } from "@chakra-ui/theme-tools";

// import { motion, useMotionValue } from 'framer-motion';

/**
 * Multi-browser scroll container with styled scrollbars.
 */
const ScrollContainer = React.forwardRef(({ children, ...rest }, ref) => {
    /**
     * Native scrollbar with custom color for Chrome-based browsers. Check the second
     * link for potentially making custom colors eventually work on Firefox, because
     * the `scrollbar-color` CSS attribute on Firefox does not work.
     * 
     * Libraries like `react-custom-scroller` and `react-custom-scroller` have too many
     * limitations and complications, such as no support for SSR or too few updates.
     * 
     * Reference: https://css-tricks.com/the-current-state-of-styling-scrollbars-in-css/
     */
    const theme = useTheme();

    const scrollbarColor = useColorModeValue('blackAlpha.100', 'primary.300');
    const scrollbarPadding = '1em';
    const scrollbarStyle = {
        // For native colored scrollbars in Webkit based browsers
        '&::-webkit-scrollbar': {
            height: '.25em',
            borderRadius: '1em',
            backgroundColor: `transparent`,
            paddingBottom: scrollbarPadding,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '1em',
            backgroundColor: scrollbarColor,
        },
        // Firefox doesn't support Webkit extensions above - use closest alternative instead
        ':not(-webkit-scrollbar)': {
            scrollbarColor: `${getColor(theme, scrollbarColor)} transparent`,
            scrollbarWidth: 'thin',
            paddingBottom: scrollbarPadding,
        }
    };

    return (<Flex
        // as={motion.div}
        ref={ref}
        overflowX="scroll"
        overscrollBehaviorX="contain"
        scrollSnapType="x mandatory"
        sx={scrollbarStyle}
        // drag="x"
        // dragConstraints={ref} // Should use ref from inner window
        // dragTransition={{ bounceStiffness: 600, bounceDamping: 8 }}
        {...rest}>
        {children}
    </Flex>);
});

export default ScrollContainer;
