import React from 'react';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

import { chakra, Box, Container, Text, useColorModeValue } from '@chakra-ui/react';
import { Accordion, AccordionItem, AccordionIcon, AccordionButton, AccordionPanel } from '@chakra-ui/react';

import { Card } from 'components/layout';
import { VectorBackground } from 'components/view';
import { LinkTranslate } from 'components/navigation';
import { SiteConfig } from 'utils/config';

const FaqItemBody = ({ title, body }) => {
    return (
        <AccordionItem
            borderColor={useColorModeValue('gray.50', 'whiteAlpha.100')}>
            <chakra.h2>
                <AccordionButton borderRadius="lg">
                    <Box flex="1" textAlign="left">
                        <Text size="lg" color="primary.300" m={0}>
                            {title}
                        </Text>
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </chakra.h2>
            <AccordionPanel pb={4} color={useColorModeValue('inherit', 'white')}>{body}</AccordionPanel>
        </AccordionItem>
    );
};

const FaqSection = ({ items, ...rest }) => {
    const { t } = useTranslation();
    return (
        <VectorBackground
            as="section"
            zIndex={0}
            pattern="plaid"
            scale={8}
            colors={[
                'secondary.300',
                'rgb(196,212,175)',
                'rgb(176,229,216)',
                'cyan.50',
            ]}
            pt={3}
            pb={12}
            {...rest}>
            <Container maxW="container.lg" my={3}>
                <Card align="center" justify="" mt={12}>
                    <chakra.h1 align="center">{t('faq_title')}</chakra.h1>
                    <Text
                        variant="muted"
                        size="lg"
                        align="center"
                        m="auto"
                        maxW="container.sm">
                        <Trans i18nKey="faq_body" t={t} components={[
                            <LinkTranslate
                                to={`mailto:${SiteConfig.siteUseEmailAddress}?subject=${t('faq_email_subject')}`}
                                isExternal={true} />
                        ]} />
                    </Text>
                    <Accordion
                        allowToggle
                        mt={6}
                        width="full">
                        {items && items.map((item, id) =>
                            <FaqItemBody key={id} {...item} />
                        )}
                    </Accordion>
                </Card>
            </Container>
        </VectorBackground>
    )
};

export default FaqSection;
