import React from 'react';
import { chakra, Box, useStyleConfig } from '@chakra-ui/react';

/**
 * Adds a card dialog behind the given children components.
 * 
 * @param {String} title Optional text to show as heading before child elements.
 */
const Card = ({ title, className, children, variant, align, ...rest }) => {
    const styles = useStyleConfig('Card', { variant });
    return (
        <Box
            alignItems={align}
            __css={styles}
            {...rest}
        >
            {title && <chakra.h1>{title}</chakra.h1>}
            {children}
        </Box>
    );
};

export default Card;
