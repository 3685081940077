
/**
 * CommonJS because 'gatsby-config.js' currently has difficulties with ESM.
 * 
 * Do not import these settings anywhere except in hooks or actual configuration
 * code. Inclusion in other files, such as any ordinary JSX component, is
 * considered to be a code smell and won't be allowed in main branches.
 */
module.exports = {
    // Site domain. Do not include a trailing slash. If `URL` environment variable
    // exists, use that. Useful for deployment without saving variables in files
    // or repositories, and Netlify adds it by default.
    siteUrl: process.env.URL || 'http://localhost:9000',

    siteTitle: 'Cheesebyte',
    siteIcon: `favicon.png`,                    // Logo in /static dir used for SEO, RSS, and App manifest
    siteDescription: 'Software Development',
    siteUseEmailAddress: 'hello@cheesebyte.nl', // 'hello' instead of 'info' in an attempt to try and keep incoming email from site separated

    // Default share image settings for metadata
    shareImageWidth: 1000,
    shareImageHeight: 523,

    // For offline manifest
    backgroundColor: '#232327',
    themeColor: 'secondary.300',
}
