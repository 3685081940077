import React from 'react';
import axios from 'axios';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Flex, Button } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Link } from 'components/navigation';
import { getServerlessFuncEndpoint } from 'utils/helper/site';
import { Input, Checkbox, TextArea, Honey } from './controls';

const formInitialValues = {
    fullName: '',
    companyName: '',
    email: '',
    userMessage: '',
    acceptedTerms: false,
    fromPath: '',
};

const formValidationSchema = Yup.object({
    fullName: Yup.string()
        .max(50, 'Must be 50 characters or less')
        .required('Required'),
    companyName: Yup.string()
        .max(50, 'Must be 50 characters or less')
        .required('Required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Required'),
    userMessage: Yup.string()
        .max(2000, 'Must be 2000 characters or less')
        .required('Required'),
    acceptedTerms: Yup.boolean()
        .required('Required')
        .oneOf([true], 'You must accept the terms and conditions.'),
});

const handleSubmission = async (values, onSubscribeSuccess, onSubscribeFail) => {
    try {
        // Discard user message for payload
        const { userMessage: _, ...cleanedValues } = values;
        const mailSendEndPoint = getServerlessFuncEndpoint('mailSend');

        // There's no 'mailTo' here to prevent exposing it in client code
        const mailData = {
            payload: cleanedValues,
            mailFrom: values.email,
            subject: `Request for contact via page '${values.fromPath}'`,
            message: values.userMessage,
        };

        // Next line *must* be awaited, otherwise error handling won't be
        // correct because the try-catch handling routine has already run
        // whenever the function call returns an error.
        await axios.post(mailSendEndPoint, JSON.stringify(mailData));
        onSubscribeSuccess(values);
    }
    catch (err) {
        // For error message details, see: https://github.com/axios/axios/blob/main/UPGRADE_GUIDE.md#error-handling
        // Or: https://axios-http.com/docs/handling_errors
        onSubscribeFail(values, err.message);
    }
}

/**
 * Form to let a user subscribe to a product or general information. Contains form without
 * any decoration (e.g. a background card or dialog to put the form in).
 *
 * Support for Netlify: https://hvitis.dev/how-to-connect-gatsby-js-with-netlify-forms
 */
const ContactForm = ({ fromPath, onSubscribeSuccess, onSubscribeFail }) => {
    const { t } = useTranslation();
    const hpName = 'notHuman';

    return (<Formik
        initialValues={{ ...formInitialValues, fromPath }}
        validationSchema={formValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
            handleSubmission(values, onSubscribeSuccess, onSubscribeFail);
            setSubmitting(false);
        }}>
        <Form
            style={{ width: '100%' }}
            netlify-honeypot={hpName}
            data-netlify="true">
            <Honey label="" name={hpName} type="text" placeholder={t('form_skip_human_placeholder')} />
            <Input label="" name="fullName" type="text" placeholder="Your full name (e.g. John Smith)" />
            <Input label="" name="companyName" type="text" placeholder="Your company name" />
            <Input label="" name="email" type="email" placeholder="Your e-mail addess" />
            <TextArea label="" name="userMessage" placeholder="Your message; include common project details if possible" rows={4} />

            <Flex direction="column">
                <Checkbox name="acceptedTerms">
                    I agree with the <Link to="/info/terms-and-conditions" isExternal={true}>terms</Link> and <Link to="/info/privacy-policy" isExternal={true}>privacy policy</Link>
                </Checkbox>
            </Flex>
            <Flex direction="row" justify="end" mt={6}>
                <Button type="submit" size="md">Send</Button>
            </Flex>
        </Form>
    </Formik>)
}

export default ContactForm;
