import { getColor } from '@chakra-ui/theme-tools';

export const pickVisibleColor = (theme, semanticColor) => {
    const hexColor = getColor(theme, semanticColor);
    if (!hexColor) {
        return 'white';
    }

    // Copied from https://stackoverflow.com/a/37540021
    const hexToR = (h) => { return parseInt((cutHex(h)).substring(0, 2), 16) }
    const hexToG = (h) => { return parseInt((cutHex(h)).substring(2, 4), 16) }
    const hexToB = (h) => { return parseInt((cutHex(h)).substring(4, 6), 16) }
    const cutHex = (h) => { return (h.charAt(0) === "#") ? h.substring(1, 7) : h }

    // About half of 256. Lower threshold equals more dark text on dark background.
    const threshold = 140;

    const hRed = hexToR(hexColor);
    const hGreen = hexToG(hexColor);
    const hBlue = hexToB(hexColor);

    const cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
    if (cBrightness > threshold) {
        return 'black';
    }
    else {
        return 'white';
    }
}
