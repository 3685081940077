import React from 'react';
import { Container, Box, Flex, Spacer } from '@chakra-ui/react';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

import { CardMessage } from 'components/view';
import { withMdxProvider, defaultMdxComponents } from 'utils/hoc/mdx';
import { SidePanel, ChipPanel, ContentBody } from './components';

import { LinkTranslate } from 'components/navigation';
import { SiteConfig } from 'utils/config';

const ContactMessageCard = ({ title }) => {
    const { t } = useTranslation();
    const { siteUseEmailAddress } = SiteConfig;

    const contactMessageTitle = t('contact_message_title')
    const contactMessageSubject = t('contact_message_email_subject');
    const contactMessageEmail = `mailto:${siteUseEmailAddress}?subject=${contactMessageSubject} - (${title})`;

    return (
        <CardMessage title={contactMessageTitle}>
            <Trans i18nKey="contact_message_body" t={t} components={[
                <LinkTranslate
                    to={contactMessageEmail}
                    isExternal={true} />
            ]} />
        </CardMessage>
    );
}

const BodySection = ({ title, icon, tags, children }) => {
    return (<Container
        as="section"
        maxW="container.xl"
        my={3}>
        <Flex
            direction={{ base: 'column', md: 'row' }}
            gap={{ base: 0, md: 12 }}
            my={6}>
            <Spacer />
            <Box
                order={1}
                display={{ base: 'block', md: 'none' }}>
                <Box>
                    <ChipPanel
                        maxW="10em"
                        title={title}
                        icon={icon}
                        tags={tags} />
                </Box>
            </Box>
            <Box
                order={2}
                display={{ base: 'none', md: 'block' }}>
                <Box
                    as="aside"
                    pos="sticky"
                    top={0}
                    mt={{ base: 0, md: -24 }}
                    pt={{ base: 0, md: 24 }}>
                    <SidePanel
                        maxW="19em"
                        title={title}
                        icon={icon}
                        tags={tags}>
                        <ContactMessageCard title={title} />
                    </SidePanel>
                </Box>
            </Box>
            <ContentBody order={{ base: 2, md: 1 }}>
                {children}
            </ContentBody>
            <Box order={3} display={{ base: 'block', md: 'none' }}>
                <ContactMessageCard title={title} />
            </Box>
        </Flex>
    </Container>);
}

export default withMdxProvider(BodySection, defaultMdxComponents);
