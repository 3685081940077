import React from 'react';
import { Box } from '@chakra-ui/react';

export const dripHorizontalProps = {
    bgImage: `url('/images/misc/pattern-drip.svg')`,
    bgSize: '7.85em',
    bgRepeat: 'repeat-x',
    height: '4em',
};

export const dripVerticalProps = {
    bgImage: `url('/images/misc/pattern-drip-90.svg')`,
    bgSize: { base: '1.75em', md: '3em' },
    bgRepeat: 'repeat-y',
    width: '4em',
};

const DripHorizontal = ({ ...rest }) => <Box
    width="full"
    {...dripHorizontalProps}
    {...rest} />;

const DripVertical = ({ ...rest }) => <Box
    height="full"
    {...dripVerticalProps}
    {...rest} />;

const Drip = ({ orientation, ...rest }) => {
    const pattern = {
        'vertical':  DripVertical,
        'horizontal': DripHorizontal,
    };

    const Component = pattern[orientation];
    return <Component {...rest} />
};

export default Drip;