import React from 'react';
import axios from 'axios';

import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Box, Text } from '@chakra-ui/react';

import { LinkTranslate } from 'components/navigation';
import { getServerlessFuncEndpoint } from 'utils/helper/site';
import { Formik, Form } from 'formik';
import { GroupedInputArea, Honey } from './controls';
import * as Yup from 'yup';

const handleSubscribe = async (values, onSubscribeSuccess, onSubscribeFail) => {
    try {
        const mailSendEndPoint = getServerlessFuncEndpoint('mailListSubscribe');
        const mailData = {
            email: values.email,
            fromPath: values.fromPath,
        };

        await axios.post(mailSendEndPoint, JSON.stringify(mailData));
        onSubscribeSuccess(values);
    }
    catch (err) {
        // For error message details, see: https://github.com/axios/axios/blob/main/UPGRADE_GUIDE.md#error-handling
        // Or: https://axios-http.com/docs/handling_errors
        onSubscribeFail(values, err.message);
    }
}

/**
 * Form to let a user subscribe to a product or general information.
 */
const SubscribeForm = ({ fromPath, onSubscribeSuccess, onSubscribeFail }) => {
    const { t } = useTranslation();
    
    const formInitialValues = { email: '', fromPath: '' };
    const formValidationSchema = Yup.object({
        email: Yup.string()
        .email(t('subscribe_validate_email'))
        .required(t('subscribe_validate_email_required')),
    });
    
    const hpName = 'notHuman';
    return (
        <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
                handleSubscribe({ ...values, fromPath }, onSubscribeSuccess, onSubscribeFail);
                setSubmitting(false);
            }}>
            <Box>
                <Form
                    netlify-honeypot={hpName}
                    data-netlify="true">
                    <Honey label="" name={hpName} type="text" placeholder={t('form_skip_human_placeholder')} />
                    <GroupedInputArea
                        content={t('subscribe_button_subscribe')}
                        label=""
                        name="email"
                        type="email"
                        placeholder={t('subscribe_placeholder_email')} />
                </Form>
                <Text variant="muted" size="xs">
                    <Trans i18nKey="subscribe_terms" t={t} components={[
                        <LinkTranslate to="/info/terms-and-conditions" isExternal={true} />,
                        <LinkTranslate to="/info/privacy-policy" isExternal={true} />
                    ]} />
                </Text>
            </Box>
        </Formik>
    );
};

export default SubscribeForm;
