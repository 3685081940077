import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@chakra-ui/react';
import { Footer } from 'components/navigation';
import { SwitchComponent } from 'components/layout';

import { NavigationBar as NavBarDesktop } from 'components/navigation/desktop';
import { NavigationBar as NavBarMobile } from 'components/navigation/mobile';

import { useNavigationItems } from 'utils/hook/useNavigationItems';
import { useTrackPageView } from 'utils/hoc/tracking';

/**
 * The default layout component wraps around each page and template. It
 * also provides the header, footer as well as the main styles, and meta
 * data for each page.
 */
const DefaultLayout = ({ children }) => {
    const [navItems] = useNavigationItems();

    useTrackPageView();

    return (<>
        <SwitchComponent
            first={NavBarMobile}
            second={NavBarDesktop}
            items={navItems} />

        <Box as="main">
            {children}
        </Box>
        <Footer />
    </>)
};

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default DefaultLayout;
