import React from 'react';

import { ForceRatio } from 'components/layout';
import { Image } from 'components/view';

const LogoHorizontal = ({ ...rest }) => <ForceRatio
    maxW="30em"
    ratio={506 / 154}
    {...rest}>
    <Image
        src="/images/logo/logo-full-horizontal.svg"
        alt="Cheesebyte Logo" />
</ForceRatio>;

const LogoVertical = ({ ...rest }) => <ForceRatio
    maxW="14em"
    ratio={365 / 478}
    {...rest}>
    <Image
        src="/images/logo/logo-full-vertical.svg"
        alt="Cheesebyte Logo" />
</ForceRatio>;

const Logo = ({ orientation }) => {
    const pattern = {
        'vertical': LogoVertical,
        'horizontal': LogoHorizontal,
    };

    const Component = pattern[orientation];
    return <Component />
}

export default Logo;
