import React from 'react';
import PropTypes from 'prop-types';

import { Flex, useStyleConfig } from '@chakra-ui/react';
import { useSemanticColor } from 'utils/hook/useColorMapping';

const getPatternFor = (name, scl, colors) => {
    name = name || 'wave';
    scl = scl || 1.0;

    // Patterns generated via https://pattern.monster - That's also the reason
    // why this code is so weird. Needs to be cleaned up sometime to import
    // as vector file instead. Apart from the variables, this doesn't have to
    // be loaded via JavaScript.
    //
    // Use https://editsvgcode.com to quickly edit the raw paths.
    let currColorId = 0;
    const composeBackgroundUrl = (svg) => `url("data:image/svg+xml,${svg}")`;
    const withSvgPattern = ({ w, h, scale, rotate, path }) => `<svg
        id='patternId'
        width='100%'
        height='100%'
        xmlns='http://www.w3.org/2000/svg'>
        <defs>
            <pattern
                id='a'
                transform-origin='0% 0%'
                patternUnits='userSpaceOnUse'
                width='${w}'
                height='${h}'
                patternTransform='scale(${scale}) rotate(${rotate})'>
                ${path}
            </pattern>
        </defs>
        <rect
            width='100%'
            height='100%'
            fill='url(#a)'/>
        </svg>`;

    const allBackgroundUrls = {
        'wave': {
            reset: currColorId = 0,
            w: 40,
            h: 20,
            scale: scl,
            rotate: 0.0,
            path: `<path
                d='M-4.798 13.573C-3.149 12.533-1.446 11.306 0 10c2.812-2.758 6.18-4.974 10-5 4.183.336 7.193
                   2.456 10 5 2.86 2.687 6.216 4.952 10 5 4.185-.315 7.35-2.48 10-5 1.452-1.386 3.107-3.085
                   4.793-4.176'
                stroke-width='1'
                stroke='hsla(259, 0%, 100%, 0.125)'
                fill='none'/>`,
        },
        'zigzag': {
            reset: currColorId = 0,
            w: 40,
            h: 60,
            scale: scl * 0.4,
            rotate: 0.0,
            path: `<rect
                x='0'
                y='0'
                width='100%'
                height='100%'
                fill='${colors[0]}'/>
            <path
                d='M-10 5l20 10L30 5l20 10'
                stroke-linejoin='round'
                stroke-linecap='round'
                stroke-width='9'
                stroke='${colors[1]}'
                fill='none'/>
            <path
                d='M-10 25l20 10 20-10 20 10'
                stroke-linejoin='round'
                stroke-linecap='round'
                stroke-width='9'
                stroke='${colors[1]}'
                fill='none'/>
            <path
                d='M-10 45l20 10 20-10 20 10'
                stroke-linejoin='round'
                stroke-linecap='round'
                stroke-width='9'
                stroke='${colors[1]}'
                fill='none'/>
            <path
                d='M-10 65l20 10 20-10 20 10'
                stroke-linejoin='round'
                stroke-linecap='round'
                stroke-width='9'
                stroke='${colors[1]}'
                fill='none'/>`,
        },
        'plaid-stripe': {
            reset: currColorId = 0,
            w: 40,
            h: 40,
            scale: scl * 1.75,
            rotate: 45,
            path: `<rect
                x='0'
                y='0'
                width='100%'
                height='100%'
                fill='${colors[0]}'/>
            <path
                d='M20 0v.45l.45-.45zm1.55 0L20 1.55v.9L22.45 0zm2 0L20 3.55v.9L24.45
                    0zm2 0L20 5.55v.9L26.45 0zm2 0L20 7.55v.9L28.45 0zm2 0L20 9.55v.9L30.45
                    0zm2 0L20 11.55v.9L32.45 0zm2 0L20 13.55v.9L34.45 0zm2 0L20 15.55v.9L36.45
                    0zm2 0L20 17.55v.9L38.45 0zm2 0L20 19.55V20h.45L40 .45V0zM40 1.55L21.55
                    20h.9L40 2.45zm0 2L23.55 20h.9L40 4.45zm0 2L25.55 20h.9L40 6.45zm0 2L27.55
                    20h.9L40 8.45zm0 2L29.55 20h.9L40 10.45zm0 2L31.55 20h.9L40 12.45zm0 2L33.55
                    20h.9L40 14.45zm0 2L35.55 20h.9L40 16.45zm0 2L37.55 20h.9L40 18.45zm0
                    2l-.45.45H40zM0 20v.45L.45 20zm1.55 0L0 21.55v.9L2.45 20zm2 0L0
                    23.55v.9L4.45 20zm2 0L0 25.55v.9L6.45 20zm2 0L0 27.55v.9L8.45 20zm2 0L0
                    29.55v.9L10.45 20zm2 0L0 31.55v.9L12.45 20zm2 0L0 33.55v.9L14.45 20zm2
                    0L0 35.55v.9L16.45 20zm2 0L0 37.55v.9L18.45 20zm2 0L0 39.55V40h.45L20
                    20.45V20zm.45 1.55L1.55 40h.9L20 22.45zm0 2L3.55 40h.9L20 24.45zm0 2L5.55
                    40h.9L20 26.45zm0 2L7.55 40h.9L20 28.45zm0 2L9.55 40h.9L20 30.45zm0 2L11.55
                    40h.9L20 32.45zm0 2L13.55 40h.9L20 34.45zm0 2L15.55 40h.9L20 36.45zm0 2L17.55
                    40h.9L20 38.45zm0 2l-.45.45H20zM20 20V0H0v20z'
                stroke-width='1'
                stroke='none'
                fill='hsla(259, 0%, 100%, .15)'/>`, // ${colors[4]}
        },
        'plaid': {
            reset: currColorId = 0,
            w: 20,
            h: 20,
            scale: scl,
            rotate: 45,
            path: `<rect
                x='0'
                y='0'
                width='100%'
                height='100%'
                fill='${colors[0]}'/>
            <path
                d='M0 0h10v10H0z'
                stroke='none'
                stroke-width='0'
                fill='${colors[currColorId++ % colors.length]}'/>
            <path
                d='M10 0h10v10H10z'
                stroke-width='0'
                stroke='none'
                fill='${colors[currColorId++ % colors.length]}'/>
            <path
                d='M0 10h10v10H0z'
                stroke-width='0'
                stroke='none'
                fill='${colors[currColorId++ % colors.length]}'/>
            <path
                d='M10 10h10v10H10z'
                stroke-width='0'
                stroke='none'
                fill='${colors[currColorId++ % colors.length]}'/>`,
        },
    };

    const composedUrl = composeBackgroundUrl(
        withSvgPattern({...allBackgroundUrls[name]})
    );

    // Replace all empty spaces with one to keep this thing according to
    // the specs. Should use `encodeURI[Component]` but didn't deliver.
    return composedUrl
        .replaceAll('#', '%23')
        .replaceAll(/\s/g, ' ');
}

/**
 * Set CSS `background-color` to apply a color to the requested pattern.
 */
const VectorBackground = ({ children, pattern, scale, variant, colors, ...rest }) => {
    const breakpointScale = scale;
    const chakraStyles = useStyleConfig('VectorBackground', { variant });

    // Automatic assignment of internal color array. Hackish.
    colors = colors || [
        '#664732',
        '#75523B',
        '#75523B',
        '#7E5F49',
        // '#6B462F',
        // '#7B5137',
        // '#7B5137',
        // '#825D45',
    ];

    // Default to flex to support child items with spacing between, etc. + convert
    // to semantic colors so they can be applied to SVG.
    const [convertedColors] = useSemanticColor(colors);
    return (
        <Flex
            {...rest}
            __css={chakraStyles}
            sx={{ bgColor: colors[0] }}
            bgImage={getPatternFor(
                pattern,
                breakpointScale,
                convertedColors)}>
            {children}
        </Flex>
    );
}

VectorBackground.propTypes = {
    children: PropTypes.node,
    pattern: PropTypes.string,
    variant: PropTypes.string,
};

export default VectorBackground;
