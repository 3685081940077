import React from 'react';

import { chakra, HStack, Text } from '@chakra-ui/react';
import { Card } from 'components/layout';
import { Icon } from 'components/view';

/**
 * Pre-built and standardised component for displaying a large user message.
 * 
 * @param {String} variant Card variant can be used here and will be passed along to underlying component.
 */
const MessageLayout = ({ icon, title, message, variant, ...rest }) => {
    return (<Card
        p={16}
        alignItems="center"
        gap={2}
        variant={variant}
        {...rest}>
        <HStack>
            <Icon name={icon} size="lg" color="primary.300" />
            <chakra.h1 noOfLines={1}>{title}</chakra.h1>
        </HStack>
        <Text textAlign="center">
            {message}
        </Text>
    </Card>);
};

export default MessageLayout;
