import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools';

/**
 * You can also use the more specific type for
 * a single part component: ComponentSingleStyleConfig
 * 
 * See: https://chakra-ui.com/docs/styled-system/theming/component-style
 */
const Link: ComponentStyleConfig = {
    baseStyle: (props) => ({
        color: 'primary.300',
        textDecorationColor: mode('primary.50', 'whiteAlpha.100')(props),
        textDecorationLine: 'underline',
        textDecorationThickness: '0.24rem',
        fontWeight: 'semibold',
        transition: 'color .4s ease-in-out',
        _hover: {
            color: mode('primary.400', 'whiteAlpha.900')(props),
            textDecorationLine: 'none',
            transition: 'color .4s ease-in-out',
        }
    }),
    // sizes: {
    //     sm: {
    //         fontSize: 'sm',
    //         px: 4,
    //         py: 3,
    //     },
    // },
    variants: {
        muted: {
            color: 'gray.700',
            fontWeight: '400',
        },
        ghost: (props) => ({
            color: 'whiteAlpha.800',
            fontWeight: '400',
            textDecorationColor: mode('gray.100', 'whiteAlpha.300')(props),
        }),
        unstyled: {
            textDecorationLine: 'none',
        }
    },
    // The default size and variant values
    defaultProps: {
        // size: 'md',
        // variant: 'outline',
    },
};

export default Link;
