
/**
 * Maps YAML frontmatter product data to menu items. Filters out products
 * based on the current build stage in `${process.env.NODE_ENV}`.
 * 
 * Available `stage` options for filtering via MDX frontmatter are:
 * 
 *   [ `local`, `test`, `staging`, `production` ]
 * 
 * @param {Array} products Flattened YAML frontmatter product item array.
 */
export const mapProductItems = (products) => products
    .filter(x => process.env.NODE_ENV !== 'production' ?
        x :
        x.stage &&
        x.stage === process.env.NODE_ENV)
    .map(x => ({
        label: x.title,
        subLabel: x.description,
        icon: x.icon,
        href: x.path,
        column: x.prio || 0,
    }));

/**
 * Maps YAML frontmatter service data to menu items.
 * 
 * @param {Array} services Flattened YAML frontmatter service item array.
 */
export const mapServiceItems = (services) => {
    const maxColumns = 2;
    let column = 0;

    return services.map(x => ({
        label: x.title,
        tags: x.tags,
        icon: x.icon,
        href: x.path,
        column: (column++) % maxColumns,
    }));
};

/**
 * Fake-maps about menu data.
 * 
 * @param {Object} t Translation object from `gatsby-plugin-react-i18next`.
 */
export const mapAboutItems = (t) => {
    const items = [{
        subLabel: t('title_business'),
        href: '/info/business',
    },
    {
        subLabel: t('title_affiliates'),
        href: '/info/affiliates',
    },
    {
        subLabel: t('title_terms_conditions'),
        href: '/info/terms-and-conditions',
    },
    {
        subLabel: t('title_privacy_policy'),
        href: '/info/privacy-policy',
    }];

    // NOTE: Annoying column prop needs to go, it only exists
    //       because the `NavigationMenuStack` was based on
    //       older and messy code. Once that's cleaned up: let it go...
    return items.map(x => ({ ...x, column: 0 }));
};

/**
 * Maps menu specific data into items that can be added to
 * the `NavigationBar` components (built on: `NavigationMenuStack`, `GenericSubNav`, etc).
 * 
 * @param {Array} allMenus List of arrays with menu items.
 * @param {Array} allLabels List of arrays with label items.
 * 
 * @returns An array with menus containing a label and menu items.
 */
export const mapNavigationItems = (allMenus, allLabels) => allMenus
    .map((items, index) => ({
        label: allLabels[index],
        items,
    }));

/**
 * Creates a set of objects with menu items for the navigation bar. Mostly
 * used to glue together the page links generated by MDX files + some extra
 * information for the about menu.
 * 
 * @param {Array} products Mapped product item array.
 * @param {Array} services Mapped service item array.
 * @param {Object} t Translation object from `gatsby-plugin-react-i18next`.
 * 
 * @example Use `flattenFrontmatterData` on MDX YAML > `mapXxxItems` afterwards > use results here.
 */
export const buildNavigationItems = (products, services, abouts, t) => {
    // Keep product and service loading separate from each other. Having
    // this data decoupled is useful for a possible future and it prevents
    // slow loading on either the Node.js and front-end side.
    const menus = [
        products,
        services,
        abouts,
    ];

    const labels = [
        t('menu_products'),
        t('menu_services'),
        t('menu_about'),
    ];

    return mapNavigationItems(menus, labels);
};
