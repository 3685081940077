import { popoverAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';
import { cssVar, mode } from '@chakra-ui/theme-tools';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

// const $tooltipBg = cssVar('tooltip-bg');
// const $popperBg = cssVar('popper-bg');
const $arrowBg = cssVar('popper-arrow-bg');
const $arrowShadowColor = cssVar('popper-arrow-shadow-color');

const baseStylePopper = defineStyle({
    zIndex: 10,
});

const baseStyleContent = defineStyle((props) => {
    // NOTE: For the future > tooltips are popovers and can be styled:
    //       https://github.com/chakra-ui/chakra-ui/issues/4695#issuecomment-991023319
    const bgColor = mode('white', 'primary.500')(props);
    const borderColor = mode('gray.50', 'primary.600')(props);

    return {
        border: 0,
        boxShadow: 'xl',
        bg: bgColor,
        p: 3,
        rounded: 'xl',
        borderColor: borderColor,
        borderStyle: 'solid',
        borderWidth: 1,

        // [$popperBg.variable]: `colors.${bg}`,
        // bg: $popperBg.reference,
        [$arrowBg.variable]: `colors.${bgColor}`,
        [$arrowShadowColor.variable]: `colors.${borderColor}`,
    }
});

const baseStyleHeader = defineStyle({
    px: 3,
    py: 2,
    borderBottomWidth: '1px',
});

const baseStyleBody = defineStyle({
    px: 3,
    py: 2,
});

const baseStyleFooter = defineStyle({
    px: 3,
    py: 2,
    borderTopWidth: '1px',
});

const baseStyleCloseButton = defineStyle({
    position: 'absolute',
    borderRadius: 'md',
    top: 1,
    insetEnd: 2,
    padding: 2,
});

const baseStyle = definePartsStyle((props) => ({
    popper: baseStylePopper,
    content: baseStyleContent(props),
    header: baseStyleHeader,
    body: baseStyleBody,
    footer: baseStyleFooter,
    closeButton: baseStyleCloseButton,
}));

export const Popover = defineMultiStyleConfig({
    baseStyle,
});

export default Popover;
