import React/*, { useState }*/ from 'react';
import { Box, AspectRatio, /*Skeleton,*/ } from '@chakra-ui/react';

/**
 * Replacement for `img` tag. Basically tries to load an image
 * by adding support for @type {Skeleton} and @type {AspectRatio}
 * to prevent needless shifts in the page layout due to possibly
 * too slow loading of images.
 * 
 * Gatsby contains its own image component to handle the latter,
 * but that doesn't work for unsupported formats. Like vector
 * (SVG) images. This ForceRatio component does.
 * 
 * NOTE: You may pass only one child via the `children` prop.
 */
const ForceRatio = ({ maxW, ratio, fadeDuration, display, variant, children, ...rest }) => {
    // const styles = useStyleConfig('ForceRatio', { variant });
    // const [isImageLoaded, setImageLoaded] = useState(false);

    return (
        <AspectRatio
            display={display}
            width="full"
            maxW={maxW}
            ratio={ratio}
            {...rest}>
            {/* <Skeleton
                isLoaded={isImageLoaded}
                fadeDuration={fadeDuration || 0}
                startColor="transparent"
                endColor="transparent"> */}
                {/* Wrapping with Box works better with odd usages of the box (...) model in specific
                    situations within the children elements */}
                <Box>
                    {children}
                </Box>
                {/* <Image
                    // onLoad={() => setImageLoaded(true)}
                    src={src}
                    alt={alt} /> */}
            {/* </Skeleton> */}
        </AspectRatio>
    );
};

export default ForceRatio;
