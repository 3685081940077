import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const WebsiteMeta = ({ canonical, title, description }) => {
    return (<>
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={canonical} />

            <link rel="apple-touch-icon" sizes="180x180" href="/site/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/site/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/site/favicon-16x16.png" />
            <link rel="manifest" href="/site/site.webmanifest" crossorigin="use-credentials" />
            <link rel="mask-icon" href="/site/safari-pinned-tab.svg" color="#5bbad5" />
            <link rel="shortcut icon" href="/favicon.ico" />
            <meta name="apple-mobile-web-app-title" content="Cheesebyte" />
            <meta name="application-name" content="Cheesebyte" />
            <meta name="msapplication-TileColor" content="#49382d" />
            <meta name="msapplication-config" content="/site/browserconfig.xml" />
            <meta name="theme-color" content="#49382d" />
        </Helmet>
    </>);
};

WebsiteMeta.propTypes = {
    canonical: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    // type: PropTypes.oneOf([`WebSite`, `Series`]).isRequired,
}

export default WebsiteMeta;
