import React, { useState } from 'react';
import { chakra, Container, VStack, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Card, MessageLayout, PageSwitchLayout } from 'components/layout';
import { SubscribeForm } from 'components/form';
import { VectorBackground } from 'components/view';
import { CurlyArrow } from 'components/visual';

const SubscribeSection = ({ fromPath, ...rest }) => {
    const { t } = useTranslation();
    const [subscriptionState, setSubscriptionState] = useState({
        visible: false,
    });

    const PageInitial = () => <Card>
        <VStack alignItems="stretch" w="full" gap={2}>
            <VStack alignItems="start">
                <HStack>
                    <chakra.h1>{t('subscribe_title')}</chakra.h1>
                    <CurlyArrow color="primary.300" />
                </HStack>
                <Text textAlign="start">{t('subscribe_description')}</Text>
            </VStack>

            <SubscribeForm
                fromPath={fromPath}
                onSubscribeSuccess={({ email }) => {
                    setSubscriptionState({
                        visible: true,
                        icon: 'bx-message-alt-check',
                        title: 'Success',
                        message: `Subscribed ${email} - please check you email to confirm.`
                    });
                }}
                onSubscribeFail={({ email }, message) => {
                    setSubscriptionState({
                        visible: true,
                        icon: 'bx-message-alt-error',
                        title: 'Error',
                        message: `Failed to subscribe using ${email}`,
                    });
                }} />
        </VStack>
    </Card>;

    const PageFinal = () => <MessageLayout
        icon={subscriptionState.icon}
        title={subscriptionState.title}
        message={subscriptionState.message} />;

    return (
        <VectorBackground
            pattern="plaid"
            scale={8}
            as="section"
            {...rest}>
            <Container maxW="container.xl" py={12}>
                <PageSwitchLayout
                    pageInitial={<PageInitial />}
                    pageFinal={<PageFinal />}
                    showFinalPage={subscriptionState.visible} />
            </Container>
        </VectorBackground>
    )
};

export default SubscribeSection;
