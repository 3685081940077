
/**
 * The 'mode' function from 'theme-tools' cannot be used to set light
 * and dark colors. In addition, it cannot be used with components
 * like the `Heading` component.
 * 
 * See:
 * 
 *    https://chakra-ui.com/docs/styled-system/text-and-layer-styles
 *    https://github.com/chakra-ui/chakra-ui/issues/2231#issuecomment-771531813
 */
 const textStyles = {
    'display-1': {
        fontFamily: `'M PLUS Rounded 1c', arial, helvetica, sans-serif`,
        fontWeight: 'semibold',
        fontSize: '3xl',

        '.chakra-ui-dark &': {
            color: 'white',
        },
    },
    'display-2': {
        fontFamily: `'M PLUS Rounded 1c', arial, helvetica, sans-serif`,
        fontWeight: 'semibold',
        fontSize: '2xl',

        '.chakra-ui-dark &': {
            color: 'whiteAlpha.800',
        },
    },
    'display-3': {
        fontFamily: `'M PLUS Rounded 1c', arial, helvetica, sans-serif`,
        fontWeight: 'semibold',
        fontSize: 'xl',

        '.chakra-ui-dark &': {
            color: 'whiteAlpha.800',
        },
    },
};

export default textStyles;
