import React from 'react';

import { chakra, Flex, Stack, Collapse, Icon, useDisclosure, useColorModeValue } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

// In normal situations, the Stack below would get a spacing of 3 instead, but that
// makes the collapse animation stutter a lot.
const StackContainer = ({ children }) => <Stack align="start" pt={3}>
    {children}
</Stack>;

const CollapseLayout = ({ title, hideCollapse, defaultIsOpen, children, ...rest }) => {
    const { isOpen, onToggle } = useDisclosure({ defaultIsOpen });
    const iconColor = useColorModeValue('gray.100', 'whiteAlpha.400');

    return (<>
        <Stack
            spacing={0}
            onClick={children && onToggle}
            {...rest}>
            <Flex
                pt={2}
                justify="space-between"
                align="center">
                <chakra.h5>{title}</chakra.h5>
                {!hideCollapse && !defaultIsOpen && children && (
                    <Icon
                        as={ChevronDownIcon}
                        size="xs"
                        transition={'all .25s ease-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        color={iconColor}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            {!defaultIsOpen ? <Collapse in={isOpen} animateOpacity>
                <StackContainer>{children}</StackContainer>
            </Collapse> :
            <StackContainer>{children}</StackContainer>}
        </Stack>
    </>);
}

export default CollapseLayout;
