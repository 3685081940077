import React from 'react';

import { chakra, Flex, VStack, Container } from '@chakra-ui/react';
import { StackInfoMain, StackInfoExtra } from '.';

const DetailPanel = ({ info }) => {
    const { title } = info;
    const roundedTopEdge = {
        base: '2xl',
        lg: 'unset',
    };

    const columnWidth = {
        base: '100%',
        lg: '50%',
    };

    return (<Container
        as={VStack}
        maxW="full"
        my={6}
        align="start"
        roundedTop={roundedTopEdge}>
        <chakra.h2 textAlign="start" m={0}>{title}</chakra.h2>
        <Flex
            direction={{ base: 'column', lg: 'row' }}
            align="start"
            justify="space-between"
            width="full"
            gap={6}>
            <StackInfoMain info={info} width={columnWidth} />
            <StackInfoExtra info={info} width={columnWidth} />
        </Flex>
    </Container>);
};

export default DetailPanel;
