import React from 'react';
import { Flex, Box, IconButton, Stack, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { Drawer, DrawerOverlay, DrawerContent, DrawerBody } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

import { Icon as BxIcon } from 'components/view';
import { CollapseLayout } from 'components/layout';
import { Link, GenericSubNav, LanguageToggle, ThemeToggle } from 'components/navigation';

const DrawerPopup = ({ disclosure, children }) => {
    /* eslint-disable jsx-a11y/no-autofocus */
    const { onClose, isOpen } = disclosure;

    return (<Drawer
        isFullHeight={false}
        placement="top"
        closeOnOverlayClick={true}
        onClose={onClose}
        isOpen={isOpen}
        /**
         * Disable to keep the UI consistent - it's not nice to make the user
         * search for the top area of the dialog for some and sometimes (if
         * there's no reference link) not.
         */
        autoFocus={false}>
        <DrawerOverlay />
        <DrawerContent
            // Force max height for Chrome @ Android, otherwise Chakra UI will
            // show the content with an overlapping input field for browser urls.
            maxH="70vh"
            roundedBottom="2xl">
            <DrawerBody>
                {children}
            </DrawerBody>
        </DrawerContent>
    </Drawer>);
};

const NavigationBar = ({ items, location, ...rest }) => {
    const contentBgColor = useColorModeValue('white', 'primary.500');
    const disclosure = useDisclosure();
    const { isOpen, onToggle } = disclosure;

    const MobileMenuButton = () => <IconButton
        onClick={onToggle}
        icon={isOpen ?
            <CloseIcon w={3} h={3} /> :
            <HamburgerIcon w={5} h={5} />}
        variant={useColorModeValue('ghost', 'white')}
        isRound={true}
        rounded="full"
        aria-label="Toggle Navigation" />;

    const MobileHomeButton = () => <IconButton
        as={Link}
        to="/"
        icon={<BxIcon name="bx-home-alt" size="xs" />}
        variant={useColorModeValue('ghost', 'white')}
        isRound={true}
        rounded="full"
        aria-label="Home" />;

    return (<Box
        pos="fixed"
        top="0"
        zIndex={40}
        width="full"
        // NOTE: Enabling this causes comparison slider to work in odd ways at product page
        // pointerEvents="none"
        {...rest}>
        <Flex
            width="full"
            minH="3.5rem"
            align="center"
            justify="space-between"
            position="relative">
            <Flex width="full" direction="column">
                <Stack
                    justify="flex-end"
                    direction="row"
                    spacing={3}
                    p={3}>
                    <MobileMenuButton />
                </Stack>

                <DrawerPopup disclosure={disclosure}>
                    <Stack
                        w="full"
                        bg={contentBgColor}
                        p={3}>
                        <Stack direction="row" justify="flex-end" spacing={3}>
                            <LanguageToggle isRound={true} />
                            <MobileHomeButton />
                            <ThemeToggle isRound={true} />
                            <MobileMenuButton />
                        </Stack>
                        {items?.map((navItem, id) => (
                            <MobileNavigationPanel key={id} {...navItem} />
                        ))}
                    </Stack>
                </DrawerPopup>
            </Flex>
        </Flex>
    </Box>);
}

const MobileNavigationPanel = ({ label, items }) => <CollapseLayout
    p={1.5}
    px={3}
    spacing={0}
    title={label}>
    <Box mt={1.5}>
        {items.map((child, id) => (
            <GenericSubNav key={id} {...child} />
        ))}
    </Box>
</CollapseLayout>;

export default NavigationBar;
