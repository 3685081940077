import { drawerAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

// From `run-if-fn`: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/utils/run-if-fn.ts
const isFunction = (value: any): value is Function =>
    typeof value === 'function';

export function runIfFn<T, U>(
    valueOrFn: T | ((...fnArgs: U[]) => T),
    ...args: U[]
): T {
    return isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn;
}

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

/**
 * Since the `maxWidth` prop references theme.sizes internally,
 * we can leverage that to size our modals.
 */
function getSize(value: string) {
    if (value === 'full') {
        return definePartsStyle({
            dialog: { maxW: '100vw', h: '100vh' },
        });
    }
    return definePartsStyle({
        dialog: { maxW: value },
    });
}

const baseStyleOverlay = defineStyle((props) => {
    return {
        bg: mode('whiteAlpha.800', 'blackAlpha.500')(props),
        backdropFilter: 'auto',
        // backdropBlur: '3px',
        backdropSaturate: mode('60%', '100%')(props),
        zIndex: 'overlay',
    };
});

const baseStyleDialogContainer = defineStyle((props) => {
    const { scrollBehavior } = props;
    return {
        display: 'flex',
        zIndex: 'modal',
        justifyContent: 'center',
        overflowX: 'hidden',
        overflowY: scrollBehavior === 'inside' ? 'hidden' : 'auto',
    };
});

const baseStyleDialog = defineStyle((props) => {
    const { isFullHeight, scrollBehavior } = props;
    const height = scrollBehavior === 'inside' ? '60vh' : '100vh';

    return {
        ...(isFullHeight && { height: height }),
        zIndex: 'modal',
        // maxH: '100vh',
        maxH: scrollBehavior === 'inside' ? 'calc(100% - 7.5rem)' : height,
        bg: mode('white', 'gray.900')(props),
        color: 'inherit',
        boxShadow: mode('lg', 'dark-lg')(props),
    }
});

const baseStyleHeader = defineStyle({
    px: '6',
    py: '4',
    fontSize: 'xl',
    fontWeight: 'semibold',
});

const baseStyleCloseButton = defineStyle({
    position: 'absolute',
    top: '2',
    insetEnd: '3',
});

const baseStyleBody = defineStyle((props) => {
    return {
        // I decided to move padding into client code because
        // otherwise it was difficult to get the styling I
        // needed (e.g. the rounded bottom below). If there's
        // a way to keep the padding in here *with* rounding
        // that would be better for sure.
        px: '0',
        py: '0',
        flex: '1',
        overflowX: 'hidden',
        overflowY: 'scroll',
        height: '60vh',
        // NOTE: This is a hack, but I don't see a way of
        //       getting this prop passed from the client code.
        roundedBottom: '2xl',
    };
});

const baseStyleFooter = defineStyle((props) => {
    return {
        px: '6',
        py: '4',
        bg: mode('gray.25', 'blackAlpha.300')(props),
    };
});

const baseStyle = definePartsStyle((props) => ({
    overlay: runIfFn(baseStyleOverlay, props),
    dialogContainer: runIfFn(baseStyleDialogContainer, props),
    dialog: runIfFn(baseStyleDialog, props),
    header: baseStyleHeader,
    closeButton: baseStyleCloseButton,
    body: runIfFn(baseStyleBody, props),
    footer: runIfFn(baseStyleFooter, props),
}));

const sizes = {
    xs: getSize('xs'),
    sm: getSize('md'),
    md: getSize('lg'),
    lg: getSize('2xl'),
    xl: getSize('4xl'),
    full: getSize('full'),
}

export const Drawer = defineMultiStyleConfig({
    baseStyle,
    sizes,
    defaultProps: {
        size: 'xs',
    },
});

export default Drawer;
