import React from 'react';
import { Helmet } from 'react-helmet';

// import 'boxicons/dist/boxicons.js';
import 'boxicons/css/boxicons.min.css';

/**
 * FontSource recommends this in its manual:
 * 
 *    import '@fontsource/m-plus-rounded-1c';
 * 
 * But that doesn't seem to work well with a Gatsby app. Assets load slowly (see issues on
 * GitHub). Gatsby plugins like `gatsby-plugin-preload-fonts` don't help much either because
 * this plugin loads too much at once (again, see issues on GitHub).
 * 
 * Info:
 * 
 *    https://github.com/fontsource/fontsource/issues/83
 * 
 * References:
 * 
 *    https://css-tricks.com/fighting-foit-and-fout-together/
 *    https://www.filamentgroup.com/lab/js-web-fonts.html
 * 
 */
import './FontDefaults.css';

const FontMeta = () => {
    const mPlusRounded1cLatin500Normal = "/fonts/M-PLUS-Rounded-1c/m-plus-rounded-1c-latin-500-normal.woff2";
    const mPlusRounded1cLatin700Normal = "/fonts/M-PLUS-Rounded-1c/m-plus-rounded-1c-latin-700-normal.woff2";

    const fontPreloadProps = {
        rel: 'preload',
        as: 'font',
        type: 'font/woff2',
        crossorigin: 'anonymous',
    };

    /**
     * Another way would be to load via:
     *    import mPlusRounded1c500 from '@fontsource/m-plus-rounded-1c/files/m-plus-rounded-1c-latin-500-normal.woff2';
     * 
     * And `link` via:
     *    <link rel="preload" as="font" href={mPlusRounded1c500} type="font/woff2" crossorigin="anonymous" />
     */
    return (<>
        <Helmet>
            <link {...fontPreloadProps} href={mPlusRounded1cLatin500Normal} />
            <link {...fontPreloadProps} href={mPlusRounded1cLatin700Normal} />
        </Helmet>
    </>);
};

export default FontMeta;
