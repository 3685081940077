import React from 'react';
import { Box, SlideFade, CloseButton } from '@chakra-ui/react';

/**
 * Helpful utility layout component to reduce the complexity of switching
 * from one page to another with an animation.
 * 
 * @param {?} pageInitial Main page to show through this layout.
 * @param {?} pageFinal Page to show after switching.
 */
const PageSwitchLayout = ({ pageInitial, pageFinal, showFinalPage, onClickClose }) => {
    // Transition isn't documented, but does work. See: https://github.com/chakra-ui/chakra-ui/issues/1017
    const offsetY = "42px";
    return <Box
        // Having display=grid for 'pageInitial' disables Link (a href) components for some reason
        display={showFinalPage && 'grid'}
        zIndex={0}
        gridTemplateColumns="auto"
        gridTemplateRows="auto">
        <Box gridArea="1 / 1 / 1 / 1">
            <SlideFade
                transition={{ enter: { duration: 0.5 } }}
                in={!showFinalPage}
                offsetY={offsetY}
                unmountOnExit={true}>
                {pageInitial}
            </SlideFade>
        </Box>
        <Box gridArea="1 / 1 / 1 / 1" zIndex={1}>
            <SlideFade
                transition={{ enter: { duration: 0.5 } }}
                in={showFinalPage}
                offsetY={offsetY}
                unmountOnExit={true}>
                {onClickClose && <CloseButton
                    alignSelf="flex-end"
                    onClick={onClickClose} />}
                {pageFinal}
            </SlideFade>
        </Box>
    </Box>;
};

export default PageSwitchLayout;
