import React from 'react';
import { Box, Image, Wrap, WrapItem, TableContainer, Table, Thead, Tbody, Tr, Th, Td, useColorModeValue } from '@chakra-ui/react';
import { MDXProvider } from "@mdx-js/react";

import { Link } from 'components/navigation';
import { Icon, PullQuote } from 'components/view';

/**
 * Customises MDX content by mapping given components to it.
 * 
 * @param {React.Component} WrappedComponent Any component that can be used in JSX.
 * @param {Object} mappedMdxComponents Object with keys to map components in MDX content.
 * @example withMdxProvider(SomeComponent, { p: props => <p {...props}/> });
 * @returns The input component, wrapped by @type {MDXProvider}.
 */
export const withMdxProvider = (WrappedComponent, mappedMdxComponents) => {
    class Wrapper extends React.PureComponent {
        render() {
            return (
                <MDXProvider
                    components={mappedMdxComponents}>
                    <WrappedComponent {...this.props} />
                </MDXProvider>
            );
        }
    }

    return Wrapper;
}

const TableWrapper = (props) => {
    return (<TableContainer
        whiteSpace="normal"
        rounded="xl"
        sx={{
            borderWidth: 1,
            borderColor: useColorModeValue('gray.50', 'blackAlpha.400'),
        }}>
        <Table size="md">
            {/* variant="striped"
            colorScheme="gray" */}
            {props.children}
        </Table>
    </TableContainer>);
};

const TableRowWrapper = (props) => {
    return (<Tr
        _even={{ bg: useColorModeValue('gray.25', 'whiteAlpha.100') }}
        border="none"
        color={useColorModeValue('gray.400', 'whiteAlpha.500')}
        {...props}>
        {props.children}
    </Tr>);
};

const TableDataWrapper = (props) => {
    return (<Td
        borderWidth={0}
        _first={{
            color: useColorModeValue('primary.200', 'white'),
            fontWeight: 'semibold',
            whiteSpace: 'nowrap',
            verticalAlign: 'top',
        }}
        {...props}>
        {props.children}
    </Td>);
};

const ListItemWrapped = (props) => {
    return (<WrapItem
        w="full"
        alignItems="top"
        {...props}>
        <Icon
            name="bx-check-circle"
            color={useColorModeValue('primary.300', 'secondary.300')}
            size="xl"
            mt=".32rem"
            me={2} />
        <Box>{props.children}</Box>
    </WrapItem>);
};

/**
 * Mapped MDX components that can be passed into @see {withMdxProvider}.
 * 
 * NOTE: Obviously needs some work to separate theme into table variants to reduce the
 *       messy code - it needs to integrate with the Chakra UI Table 'striped' variant.
 */
export const defaultMdxComponents = {
    // Would like to use 'wrapper' here instead of export default PageLayout in each MDX, but how?
    // MDXProvider uses a href by default - use a i18n + Chakra-styled Link instead
    a: props => <Link to={`${props.href}`} mx={0.5}>{props.children}</Link>,

    ul: props => <Wrap listStyleType="none" mb={3} {...props} />,
    li: props => <ListItemWrapped {...props}>{props.children}</ListItemWrapped>,

    blockquote: props => <PullQuote {...props}>{props.children}</PullQuote>,
    img: props => <Image rounded="2xl" {...props} />,

    table: props => <TableWrapper {...props}>{props.children}</TableWrapper>,
    thead: props => <Thead {...props}>{props.children}</Thead>,
    tr: props => <TableRowWrapper {...props}>{props.children}</TableRowWrapper>,
    th: props => <Th borderWidth={0} pb={1.5} fontSize="medium" textTransform="none" {...props}>{props.children}</Th>,
    tbody: props => <Tbody {...props}>{props.children}</Tbody>,
    td: props => <TableDataWrapper {...props}>{props.children}</TableDataWrapper>,
};

/**
 * Creates an array of items with data from MDX frontmatter for each item. Makes
 * it easier to access these attributes in UI files.
 * 
 * @param {Object} graphQlData The object that contains `edges` after querying Gatsby data via GraphQL.
 * @param {String} language What language to use for filtering the data. Excludes data for other languages.
 * @returns Item array with flattened frontmatter data + ID.
 */
export const flattenFrontmatterData = (graphQlData, language) => graphQlData
    .edges
    .map(item => ({
        id: item.node.id,
        ...item.node.frontmatter,
    }))
    .filter(x =>
        x.language === language
    );
