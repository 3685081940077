import React from 'react';
import { Text, Tag, Heading, VStack, HStack, useColorModeValue } from '@chakra-ui/react';

import { Link } from 'components/navigation';
import { Icon as BxIcon } from 'components/view';

const SubNavItemTags = ({ tags }) => {
    const tagBackgroundColor = useColorModeValue('gray.25', 'blackAlpha.200');
    const tagColor = useColorModeValue('gray.300', 'whiteAlpha.600');

    return (<HStack>
        {tags.slice(0, 3).map((singleTag, id) =>
            <Tag
                key={id}
                size="sm"
                bg={tagBackgroundColor}
                color={tagColor}
                whiteSpace="nowrap"
                mx={0}
                my={0.75}>{singleTag}</Tag>)}
    </HStack>);
};

const SubNavItemLabelBig = ({ title, description }) => {
    const titleColor = useColorModeValue('gray.700', 'white');
    const titleHoverColor = useColorModeValue('primary.300', 'gray.50');

    return (<>
        <Heading
            as="h5"
            size="sm"
            m={0} p={0}
            color={titleColor}
            _groupHover={{ color: titleHoverColor }}
            transition={'all .25s ease-out'}>
            {title}
        </Heading>
        <Text
            p={0} m={0}
            variant="muted"
            fontSize="sm"
            color={useColorModeValue('gray.400', 'whiteAlpha.600')}>
            {description}
        </Text>
    </>);
};

const SubNavItemLabelSmall = ({ title }) => {
    const titleColor = useColorModeValue('gray.400', 'white');
    const titleHoverColor = useColorModeValue('primary.400', 'black');

    return (<Text
        p={0} m={0}
        variant="muted"
        fontSize="sm"
        color={titleColor}
        transition={'all .25s ease-out'}
        _groupHover={{
            textDecoration: 'none',
            color: titleHoverColor,
        }}>
        {title}
    </Text>);
};

// Should move this into `desktop` directory once mobile navigation gets its own
// components to make navigation on mobile layouts easier and quicker to use.
const GenericSubNav = ({ label, href, subLabel, icon, tags }) => {
    const hoverColor = useColorModeValue('gray.25', 'primary.400');
    const iconColor = useColorModeValue('gray.500', 'whiteAlpha.600');

    return (<Link
        to={href}
        role="group"
        display="block"
        width="full"
        p={2}
        rounded="md"
        _hover={{ bg: label && hoverColor }}
        textDecorationLine="none">
        <HStack align="top">
            {icon && <BxIcon
                name={icon}
                // color={iconColor}
                bgClip='text'
                bgGradient='linear(to-tr, secondary.500, secondary.400, pink.300)'
                size="sm"
                px={2} />}
            <VStack align="start">
                {label && <SubNavItemLabelBig title={label} description={subLabel} />}
                {(!label && subLabel) && <SubNavItemLabelSmall title={subLabel} />}
                {tags && <SubNavItemTags tags={tags} />}
            </VStack>
        </HStack>
    </Link>);
};

export default GenericSubNav;
