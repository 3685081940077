import React from 'react';
import PropTypes from 'prop-types';

import { TocSection } from 'components/section/info';
import { PageHeader, PageFooter } from 'components/view';
import { DefaultLayout } from 'components/layout';
import { MetaData } from 'components/meta';

/**
 * Basic template page for containing the children of an MDX post. To use: write a Markdown / JSX
 * file in the `pages` directory and it will show up with this template because it has been
 * configured as such for the `gatsby-plugin-mdx` plugin (defaults to the `pages` directory
 * and the usage of this file as layout - see `gatsby-config.js`).
 */
const Info = ({ location, children }) => <>
    <MetaData location={location} />
    <DefaultLayout>
        <PageHeader />
        <TocSection>{children}</TocSection>
        <PageFooter />
    </DefaultLayout>
</>;

Info.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    children: PropTypes.node.isRequired,
};

export default Info;
