import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { mode } from '@chakra-ui/theme-tools';

/**
 * You can also use the more specific type for
 * a single part component: ComponentSingleStyleConfig.
 * 
 * WARNING: For now it is not possible to style Heading components due to an issue
 *          with Chakra UI. See README.md for further information.
 * 
 * See: https://chakra-ui.com/docs/styled-system/theming/component-style
 */
const Heading: ComponentStyleConfig = {
    baseStyle: (props) => ({
        color: mode('black', 'white')(props),
        fontSize: '4xl',
        fontWeight: 'bold',
        marginBottom: '.5rem',
        marginTop: '.75rem',
    }),
    sizes: {
        xs: {
        },
        md: {
        },
        lg: {
        },
        xl: {
        },
        '2xl': {
        }
    },
    variants: {
        base: (props) => ({
            color: mode('black', 'white')(props),
        })
    },
};

export default Heading;
