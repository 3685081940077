import React from 'react';
import { chakra, Box, Container, SimpleGrid, Text, Stack, HStack, VStack } from '@chakra-ui/react';
import { Icon } from 'components/view';

const GridListWithHeading = ({ heading, description, items }) => {
    const gridColumns = {
        base: 1,
        md: 2,
        // lg: 4,
    };

    return (<Box p={3}>
        <Stack
            as={Container}
            spacing={4}
            maxW="3xl"
            textAlign="center">
            <chakra.h1>{heading}</chakra.h1>
            <Text
                variant="muted"
                size="lg">
                {description}
            </Text>
        </Stack>

        <Container
            maxW="6xl"
            mt={10}>
            <SimpleGrid
                columns={gridColumns}
                spacing={10}>
                {items?.map((feature) => (
                    <HStack key={feature.id} align="top">
                        <Icon
                            name={feature.icon}
                            // color="primary.300"
                            bgClip='text'
                            bgGradient='linear(to-tr, secondary.500, secondary.400, pink.300)'
                            size="sm"
                            px={2}
                            py={0.75} />
                        <VStack align="start">
                            <chakra.h5>{feature.title}</chakra.h5>
                            <Text>{feature.description}</Text>
                        </VStack>
                    </HStack>
                ))}
            </SimpleGrid>
        </Container>
    </Box>);
}

export default GridListWithHeading;
