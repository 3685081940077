import React from 'react';
import { Link } from 'components/navigation';

/**
 * Chakra UI @type {Link} with i18n support via `gatsby-plugin-react-i18next`. Should be
 * used together with i18next's @type {Trans} component to fill in interpolated entries
 * in the JSON locale files.
 * 
 * For more information, see:
 * 
 *  https://react.i18next.com/latest/trans-component
 *  https://www.i18next.com/translation-function/interpolation
 * 
 * @param {String} to       The URL to open after activating this link.
 * @param {Node} children   Child components to show as part of the link. Usually this is text only.
 * 
 * @example "description": "Please take me to the <0>home page</0>."
 * @example <Trans i18nKey="description" t={t} components={[<LinkTranslate to="/" />]} />
 */
const LinkTranslate = ({ to, children, ...rest }) => {
    return <Link to={to || ''} {...rest}>{children}</Link>;
}

export default LinkTranslate;
