import React from 'react';
import { chakra, Flex, Box, Avatar, Text, useColorModeValue } from '@chakra-ui/react';

import { Icon } from 'components/view';

/**
 * Displays a "pull quote" like in newspapers and on fancy websites. To get a big
 * quote icon, please start your children elements with a 'p' (paragraph).
 */
const PullQuote = ({ author, description, children, variant, quoteColor, ...rest }) => {
    const spanColor = useColorModeValue('gray.25', 'whiteAlpha.50');
    const iconColor = useColorModeValue('primary.300', 'secondary.300');
    const hasNoDecoration = variant === 'unstyled';

    return (
        <chakra.figure
            pos="relative"
            mx={hasNoDecoration ? 0 : 6}
            ps={hasNoDecoration ? 0 : 5}
            {...rest}>
            {!hasNoDecoration && <chakra.span
                pos="absolute"
                top={0}
                left={0}
                height="100%"
                bgColor={spanColor}
                width="0.75em"
                rounded="lg" />}
            <chakra.blockquote
                fontSize={{ base: 'md', md: 'lg' }}
                fontWeight="light"
                // fontFamily="monospace"
                ps={hasNoDecoration ? 0 : 5}
                color={quoteColor || 'gray.500'}>
                <Icon
                    name="bxs-quote-alt-right"
                    size="sm"
                    color={iconColor}
                    pe={3}
                    pb={3} />
                {children}
            </chakra.blockquote>

            {author && description &&
                <Flex as="figcaption" align="center" pt={2} ps={5}>
                    <Avatar src="/images/logo-face.png" width="18" alt="Avatar" />
                    {/* <Box width="18" color="secondary.300" rounded="lg" /> */}
                    <Box ps={5}>
                        <chakra.h6 fontWeight="semibold" lineHeight="base" mb={0}>{author}</chakra.h6>
                        <Text variant="muted" size="xs" m={0}>{description}</Text>
                    </Box>
                </Flex>}
        </chakra.figure>
    );
};

export default PullQuote;
