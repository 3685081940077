import React from 'react';
import { Box } from '@chakra-ui/react';

const GridStack = ({ children, ...rest }) => {
    // Min width and height are equired to prevent layout shifting. Let
    // parent components do the sizing work.
    return (<Box
        display="grid"
        gridTemplateColumns="auto"
        gridTemplateRows="auto"
        minWidth="full"
        minHeight="full"
        {...rest}>
        {children}
    </Box>);
}

const GridStackItem = ({ children, ...rest }) => {
    return (<Box
        gridArea="1 / 1 / 1 / 1"
        {...rest}>
        {children}
    </Box>)
}

// Use JSX dot-notation as recommended by React:
// https://reactjs.org/docs/jsx-in-depth.html#using-dot-notation-for-jsx-type
GridStack.Item = GridStackItem;

export default GridStack;
