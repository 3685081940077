import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { VectorBackground, Icon } from 'components/view';
import { ForceRatio } from 'components/layout';

const BigDetailIcon = ({ icon, maxW, iconSize, hasOutline, ...rest }) => {
    // Bit hacky to use 0.5 as offset to hide the visual rendering errors
    // on top of 5em, but required due to ForceRatio.
    return (<ForceRatio maxW={maxW} height="min-content" ratio={1} {...rest}>
        <VectorBackground
            position="relative"
            pattern="plaid"
            scale={iconSize / 3.15}
            w="full"
            h="full"
            borderRadius={{ base: '1.6em', md: '4.15em', lg: '4.95em' }}>
            {hasOutline && <Box
                position="absolute"
                top={0}
                left={0}
                bottom={0}
                right={0}
                w="full"
                h="full"
                borderWidth={{ base: '.25em', md: '.75em' }}
                borderColor="secondary.300"
                borderRadius={{ base: '1em', md: '3em', lg: '5em' }} />}
            <Flex
                w="full"
                h="full"
                p={1}
                direction="column"
                align="center"
                justifyContent="center">
                <Icon
                    name={icon}
                    // Font size shifts layout due to breakpoint (media query = slow loading of CSS)
                    // That's why transform is used instead - please keep it that way
                    // fontSize={{ base: '6rem', md: '15rem', lg: '17rem' }}
                    transform={`scale(${iconSize})`}
                    color="white" />
            </Flex>
        </VectorBackground>
    </ForceRatio>);
};

export default BigDetailIcon;
