import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { chakra, Box, Stack, Center, Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/react';

import { getCurrencySymbol } from 'utils/helper/currency';
import { PlanBottomArea } from './';

const PlanColumn = ({ name, price, ...rest }) => {
    const { language, t } = useI18next();
    const currency = 'EUR';

    // The price field can display other types of information, such as "free"
    // when the price is 0 dollars or euros.
    let isNonPriceValue = price === 0;
    let displayPrice = price;

    // If validation indicates we need to display something else, set it here
    if (isNonPriceValue) {
        displayPrice = t('product_pricing_plan_free');
    }

    return (<Center py={6}>
        <Box
            borderWidth={1}
            borderColor={useColorModeValue('gray.50', 'primary.800')}
            minW="20rem"
            bg={useColorModeValue('white', 'primary.400')}
            boxShadow="2xl"
            rounded="lg"
            overflow="hidden">
            <Stack
                textAlign="center"
                p={6}
                color={useColorModeValue('gray.800', 'white')}
                align="center">
                <chakra.h4 p={0} m={0} color={useColorModeValue('primary.300', 'white')}>{name}</chakra.h4>
                <Stack direction="column" align="center" justify="center">
                    <Stack direction="row" align="center" justify="center">
                        <Text fontSize="2xl" m={0}>
                            {!isNonPriceValue && getCurrencySymbol(language, currency)}
                        </Text>
                        <chakra.h1 m={0}>
                            {!isNonPriceValue ? displayPrice.toFixed(2) : displayPrice}
                            {/* {displayPrice.toLocaleString(language,
                                {
                                    currency,
                                    currencyDisplay: "symbol",
                                    style: "currency"
                                })} */}
                        </chakra.h1>
                    </Stack>
                    <Text
                        variant="muted"
                        rounded="md"
                        px={3}
                        py={1}
                        bg={useColorModeValue('gray.50', 'blackAlpha.300')}
                        color={useColorModeValue('gray.400', 'gray.50')}
                        visibility={!isNonPriceValue ? 'visible' : 'hidden'}>{t('product_pricing_item_per')}</Text>
                </Stack>
            </Stack>
            <PlanBottomArea {...rest} />
        </Box>
    </Center>);
}

export default PlanColumn;
