import React from 'react';
import { Box } from '@chakra-ui/react';

/**
 * A way to show or hide two components based on the provided
 * breaking points. There are multiple ways to do this with
 * Chrakra UI. The following method keeps breaking:
 * 
 *     const SwitchComponent = useBreakpointValue({
 *          base: SwitchFirst,
 *          md: SwitchSecond,
 *      }, { ssr: true/false, fallback: 'md' });
 * 
 * So the current method is implemented by simply setting
 * the `visibility`, `display` and `opacity` props. Please
 * make sure they can be set on the given components.
 * 
 * It feels rather hacky, but it's the most stable option
 * for now until I can find something better (both in
 * terms of syntax and runtime performance).
 * 
 * Warning: input components should always be name or object
 *          of the component only, not instantiated components.
 */
const SwitchComponent = ({ first, second, breakpoint, ...rest }) => {
    breakpoint = breakpoint || 'md';

    const switchPropsFirst = {
        visibility: { base: 'visible', [breakpoint]: 'collapse' },
        display: { base: 'inherit', [breakpoint]: 'none' },
    };

    const switchPropsSecond = {
        visibility: { base: 'collapse', [breakpoint]: 'visible' },
        display: { base: 'none', [breakpoint]: 'inherit' },
    };

    // Turn input into JSX components
    const FirstComponent = first;
    const SecondComponent = second;

    // I've tried to actually not render and truly hide compenents for desktop
    // or mobile, but it's not worth it because it will flash (if it's based
    // on breaking points via CSS due to too slow loading) or it's buggy due
    // to props not being passed on correctly.
    //
    // Components wrapped in a Box to make sure the `display` property can
    // be used by the component themselves.
    return (<>
        <Box {...switchPropsFirst}><FirstComponent {...rest} /></Box>
        <Box {...switchPropsSecond}><SecondComponent {...rest} /></Box>
    </>);
};

export default SwitchComponent;
