import { useState, useEffect } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export const usePastExperiences = (frontmatterExperienceItems) => {
    /* eslint-disable react-hooks/exhaustive-deps */
    const { t } = useTranslation();
    const [experienceItems, setExperienceItems] = useState([{}]);

    // NOTE: Doesn't need to be useEffect. Should research something like 'react-query' to do this better.
    useEffect(() => {
        const fetchItems = async () => {
            const defaultIcon = 'bx-check-shield';
            const tagsCombined = t('detail_project_next_tags');
            const items = [...frontmatterExperienceItems.map((singleItem) => ({
                ...singleItem,
                icon: singleItem.link?.length > 0 ? 'bx-shield-quarter' : defaultIcon,
                tags: singleItem.tags,
            })), {
                title: t('detail_project_next_title'),
                task: t('detail_project_next_body'),
                icon: defaultIcon,
                tags: tagsCombined && tagsCombined.split(' '),
                required: [],
                outcome: [],
                link: [],
            }];

            setExperienceItems(items);
        };

        fetchItems().catch(console.error);
    }, []);

    return [experienceItems];
};
