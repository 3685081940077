import React, { useState } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Link, Text, Stack, useColorModeValue } from '@chakra-ui/react';

import { Icon } from 'components/view';
import { NavigationMenuItem } from 'components/navigation';

const LanguageNavigationSubItem = ({ label, value, url, onItemClick, selectedLanguage }) => {
    const color = useColorModeValue('gray.300', 'white');
    const hoverColor = useColorModeValue('primary.400', 'gray.900');
    const iconColor = useColorModeValue('gray.700', 'primary.100');

    // The url/href prop shouldn't be required because it's all handled via onClick, but
    // Chakra UI enforces accessability rules. Without href, it's not (by default) possible
    // to go through the menu items with the tab key.
    return (<Link
        href={url}
        onClick={() => onItemClick(value)}
        role="group"
        display="block"
        width={52}
        py={1}
        rounded="md"
        textDecorationLine="none">
        <Stack direction="row" align="top">
            {value === selectedLanguage ?
                <Icon name="bx-check" color={iconColor} size="xs" mt={1.5} mx={0} px={0} /> :
                <Icon name="bx-check" color={iconColor} size="xs" mt={1.5} mx={0} px={0} visibility="hidden" />}
            <Text
                m={0} p={0}
                color={color}
                _groupHover={{ color: hoverColor }}
                transition={'all .25s ease-out'}>
                {label}
            </Text>
        </Stack>
    </Link>);
};

/**
 * Language picker based on popup menus from Chakra UI. Goes through all
 * settings from `gatsby-plugin-react-i18next` to get available languages.
 * 
 * For more languages; specify them via the plugin settings in `gatsby-config.js`.
 */
const LanguageToggle = ({ isRound }) => {
    const { languages, originalPath, language, changeLanguage } = useI18next();
    const languageNames = new Intl.DisplayNames([language], { type: 'language' });
    const languageItems = languages.map(lng => ({ value: lng, label: languageNames.of(lng) }));

    const [selectedLanguage, setSelectedLanguage] = useState(language);
    const handleItemClick = (updatedLanguage) => {
        // updatedLanguage / selectedLanguage == 'en', 'nl', etc.
        setSelectedLanguage(updatedLanguage)
        changeLanguage(updatedLanguage, originalPath);
    }

    return (<NavigationMenuItem
        label={selectedLanguage.toUpperCase()}
        buttonVariant="navlink-dark"
        isRound={isRound}>
        <Stack direction="column">
            {languageItems.map((languageItemDetail, id) => (
                <LanguageNavigationSubItem
                    key={id}
                    onItemClick={handleItemClick}
                    selectedLanguage={selectedLanguage}
                    url={`/${languageItemDetail.value}${originalPath}`}
                    {...languageItemDetail} />
            ))}
        </Stack>
    </NavigationMenuItem>);
};

export default LanguageToggle;
