import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { chakra, Box, VStack, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { Icon } from 'components/view';
import { Link } from 'components/navigation';

const WrappedListIcon = ({ children, ...rest }) => {
    return (<WrapItem
        w="full"
        alignItems="top"
        {...rest}>
        <Icon
            name="bx-check-circle"
            color="primary.300"
            size="xl"
            mt=".32rem"
            me={2} />
        <Box>{children}</Box>
    </WrapItem>);
};

const StackInfoMain = ({ info, width }) => {
    const { task, outcome, link } = info;
    const { t } = useTranslation();

    const hasOutcome = outcome?.length > 0;
    const hasReference = link?.length > 0;

    // Hacks because I ran out of time figuring why link isn't capped by parent width
    // const maxLinkWidth = {
    //     base: '16em',
    //     lg: '20em',
    // };

    return (<VStack align="start" w={width}>
        {/* Job title description(s) */}
        <Text variant="muted" size="lg" m={0}>{task}</Text>

        {/* What was delivered in terms of a business perspective */}
        {hasOutcome && <>
            <chakra.h5>{t('detail_panel_main_outcome_title')}</chakra.h5>
            <Wrap listStyleType="none" mb={3}>
                {outcome.map((x, id) => <WrappedListIcon key={id}>{x}</WrappedListIcon>)}
            </Wrap>
        </>}

        {/* Proof that the project exists */}
        <chakra.h5 pt={3}>{t('detail_panel_main_reference_title')}</chakra.h5>
        {hasReference && <>
            {link.map((urlToVisit, id) => (
                <Link
                    key={id}
                    to={urlToVisit}
                    // maxW={maxLinkWidth}
                    noOfLines={1}>
                    {urlToVisit
                        ?.replace('https://www.', '')
                        ?.replace('https://', '')}
                </Link>
            ))}
        </>}

        {/* Indicate absense of references with message to explain it might be restricted by NDA */}
        {!hasReference && <Text size="sm">
            {t('detail_panel_main_reference_body')}
        </Text>}
    </VStack>);
};

export default StackInfoMain;
