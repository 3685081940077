import { alertAnatomy as parts } from "@chakra-ui/anatomy";
import { getColor, mode, transparentize } from "@chakra-ui/theme-tools";
import type { /*PartsStyleObject,*/ PartsStyleFunction, StyleFunctionProps } from "@chakra-ui/theme-tools";

// Based on https://github.com/chakra-ui/chakra-ui/blob/b983e4ceb7c1e794c2b901ce16f12016836d40d6/packages/theme/src/components/alert.ts
// NOTE: Toasts are based on, and therefore styled as, alerts as well
const baseStyle: PartsStyleFunction<typeof parts> = (props) => {
    return {
        container: {
            px: 4,
            py: 3,
            borderWidth: 1,
            borderColor: mode('gray.50', 'whiteAlpha.50')(props),
            rounded: 'lg',
        },
        title: {
            fontWeight: 'bold',
            lineHeight: 6,
            color: mode('black', 'white')(props),
            marginEnd: 2,
        },
        description: {
            lineHeight: 6,
        },
        icon: {
            flexShrink: 0,
            marginEnd: 0,
            w: 5,
            h: 6,
        },
    }
}

function getBg(props: StyleFunctionProps): string {
    const { theme, colorScheme: c } = props
    const lightBg = 'white';// getColor(theme, `${c}.100`, c)
    const darkBg = 'whiteAlpha.50';// transparentize(`${c}.100`, 0.16)(theme)
    return mode(lightBg, darkBg)(props)
}

const variantSubtle: PartsStyleFunction<typeof parts> = (props) => {
    const { colorScheme: c } = props
    return {
        container: { bg: getBg(props) },
        icon: { color: mode(`${c}.300`, `${c}.100`)(props) },
    }
}

const variantLeftAccent: PartsStyleFunction<typeof parts> = (props) => {
    const { colorScheme: c } = props
    return {
        container: {
            paddingStart: 3,
            borderStartWidth: "4px",
            borderStartColor: mode(`${c}.300`, `${c}.100`)(props),
            bg: getBg(props),
        },
        icon: {
            color: mode(`${c}.300`, `${c}.100`)(props),
        },
    }
}

const variantTopAccent: PartsStyleFunction<typeof parts> = (props) => {
    const { colorScheme: c } = props
    return {
        container: {
            pt: 2,
            borderTopWidth: "4px",
            borderTopColor: mode(`${c}.300`, `${c}.100`)(props),
            bg: getBg(props),
        },
        icon: {
            color: mode(`${c}.300`, `${c}.100`)(props),
        },
    }
}

const variantSolid: PartsStyleFunction<typeof parts> = (props) => {
    const { colorScheme: c } = props
    return {
        container: {
            bg: mode(`${c}.300`, `${c}.100`)(props),
            color: mode(`white`, `gray.900`)(props),
        },
    }
}

const variants = {
    subtle: variantSubtle,
    'left-accent': variantLeftAccent,
    'top-accent': variantTopAccent,
    solid: variantSolid,
}

const defaultProps = {
    variant: 'left-accent',
    colorScheme: 'primary',
}

export default {
    parts: parts.keys,
    baseStyle,
    variants,
    defaultProps,
}
