import React from 'react';
import { Box } from '@chakra-ui/react';

const ContentBody = ({ children, ...rest }) =>
    <Box
        as="article"
        // direction="row"
        {...rest}>
        {children}
    </Box>

export default ContentBody;
