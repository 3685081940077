import React from 'react';

import { Stack } from '@chakra-ui/react';
import { GenericSubNav } from 'components/navigation';

export const NavigationMenuStack = ({ subMenuItems }) => {
    // To get dynamic max column count:
    // subMenuItems.reduce((accum, curr) => Math.max(accum, curr.column), 0)
    return (<Stack
        direction="row"
        gap={0}
        spacing={0}>
        <Stack direction="column">
            {subMenuItems
                .filter(x => x.column === 0)
                .map((child, id) => (
                <GenericSubNav
                    key={id}
                    {...child} />
            ))}
        </Stack>
        <Stack direction="column">
            {subMenuItems
                .filter(x => x.column === 1)
                .map((child, id) => (
                <GenericSubNav
                    key={id}
                    {...child} />
            ))}
        </Stack>
    </Stack>);
};
