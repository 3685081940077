import React from 'react';

import { Flex, Box, Text, useColorModeValue } from '@chakra-ui/react';
import { Icon } from 'components/view';

const CardMessage = ({ title, children }) => {
    return (<Flex
        direction="column"
        align="start"
        mt={6}
        px={6}
        bg={useColorModeValue('gray.25', 'blackAlpha.300')}
        rounded="xl">
        <Flex
            flexDirection="row"
            align="center"
            justify="center"
            mt={1.5}>
            <Icon
                name="bx-right-arrow-circle"
                size="sm"
                color="secondary.300"
                mt={1.5}
                me={1.5} />
            <Box
                layerStyle="display-2"
                textStyle="display-2">
                {title}
            </Box>
        </Flex>
        <Text>{children}</Text>
    </Flex>);
}

export default CardMessage;
