import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { mode } from '@chakra-ui/theme-tools';

const Card: ComponentStyleConfig = {
    baseStyle: (props) => ({
        display: 'flex',
        flexDirection: 'column',
        background: mode('white', 'gray.800')(props),
        alignItems: 'start',
        // gap: 6,
    }),
    variants: {
        unstyled: (props) => ({
            padding: 6,
            background: 'transparent',
        }),
        base: (props) => ({
            padding: 6,
            rounded: '2xl',
            border: 1,
            borderColor: mode('gray.50', 'blackAlpha.400')(props),
            borderStyle: 'solid',
            borderRadius: 'xl',
            boxShadow: 'xl',
        }),
        ghost: (props) => ({
            background: mode('white', 'gray.800')(props),
            padding: 6,
            rounded: '2xl',
            border: 0,
            borderStyle: 'none',
            borderRadius: '2xl',
            shadow: 0,
        }),
        primary: (props) => ({
            padding: 6,
            rounded: '2xl',
            background: mode('gray.25', 'primary.500')(props),
            border: 1,
            borderColor: mode('gray.50', 'primary.400')(props),
            borderStyle: 'solid',
            borderRadius: 'xl',
            boxShadow: 'xl',
        }),
    },
    defaultProps: {
        variant: 'base',
    },
}

export default Card;
