import React from 'react';
import { Box } from '@chakra-ui/react';

const ThumbArrows = ({ color, ...rest }) =>
(
    <Box
        sx={{ color }}
        {...rest}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="8"
            fill="currentColor">
            <path d="M8 .5v7L12 4zM0 4l4 3.5v-7z" fill="white" fillRule="nonzero" />
        </svg>
    </Box>
);

export default ThumbArrows;
