import { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { flattenFrontmatterData } from 'utils/hoc/mdx';
import { buildNavigationItems, mapProductItems, mapServiceItems, mapAboutItems } from 'utils/config/navigate';

const useMdxMetadata = () => {
    const { allProduct, allService } = useStaticQuery(
        graphql`query DefaultLayoutSettingsQuery {
            allProduct: allMdx(
                filter: {
                    frontmatter: {
                        template: { eq: "Product" }
                    }
                },
                sort: {
                    fields: frontmatter___title,
                    order: ASC
                }
            ) { ...MdxProductFullFragment }
            allService: allMdx(
                filter: {
                    frontmatter: {
                        template: { eq: "Service" }
                    }
                },
                sort: {
                    fields: frontmatter___title,
                    order: ASC
                }) { ...MdxServiceFullFragment }
        }`);

    return {
        allProduct,
        allService,
    };
};

export const useNavigationItems = () => {
    /* eslint-disable react-hooks/exhaustive-deps */
    const { language, t } = useI18next();
    const { allService, allProduct } = useMdxMetadata();

    // Use temporary holder until async loader is done - user won't see this
    // because it only takes a second and it's difficult to be that quick :)
    const [navItems, setNavItems] = useState(buildNavigationItems([], [], [], t));

    useEffect(() => {
        const fetchItems = async () => {
            // Map to make services an array with a flat object containing: { id, title, template, language, etc }
            const services = flattenFrontmatterData(allService, language);
            const products = flattenFrontmatterData(allProduct, language);

            const items = buildNavigationItems(
                mapProductItems(products),
                mapServiceItems(services),
                mapAboutItems(t),
                t,
            );

            setNavItems(items);
        };

        fetchItems().catch(console.error);
    }, []);

    return [navItems];
};
