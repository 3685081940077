import React from 'react';
import { Flex, Input as ChakraInput, Text, useColorModeValue } from '@chakra-ui/react';
import { useField } from 'formik';

/**
 * Honeypot field. Won't be visible or filled in by humans and is used
 * to weed out bots that try to enter form data.
 */
const Honey = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field/*, meta*/] = useField(props);
    return (
        <Flex
            direction="row"
            justify="space-between"
            align="baseline"
            mb={5}
            display="none">
            {label &&<Flex direction="column" width="32">
                <Text
                    m={0}
                    fontWeight="semibold"
                    htmlFor={props.id}>
                        {label}
                </Text>
            </Flex>}
            <Flex
                direction="column"
                flexGrow={1}>
                <ChakraInput
                    name={props.name}
                    size="lg"
                    borderColor={useColorModeValue('gray.100', 'blackAlpha.500')}
                    _hover={{
                        borderColor: useColorModeValue('primary.200', 'primary.300'),
                    }}
                    _placeholder={{
                        color: useColorModeValue('gray.300', 'gray.700'),
                        fontSize: 'md'
                    }}
                    {...field}
                    {...props}
                    autoComplete="off"
                    tabIndex="-1" />
            </Flex>
            {/* {meta.touched && meta.error ? (
                <div class="error">{meta.error}</div>
            ) : null} */}
        </Flex>
    );
};

export default Honey;
