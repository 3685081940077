
/**
 * See:
 *  https://chakra-ui.com/docs/styled-system/theming/theme#colors
 */
const colors = {
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
    // primary.800 = https://smart-swatch.netlify.app/#1c1916 / https://smart-swatch.netlify.app/#16171c
    gray:
    {
        25: '#fcfbfa',
        50: '#f1f1f4',
        // 100: '#d6d6d8',
        // 200: '#bababf',
        // 300: '#9e9fa7',
        // 400: '#818390',
        // 500: '#686a78',
        // 600: '#51525c',
        // 700: '#3a3b41',
        // 800: '#232327',
        // 900: '#0b0c0e',
        // 50: '#f4f2f1',
        100: '#dad9d9',
        200: '#c1c0bf',
        300: '#aaa7a4',
        400: '#938d88',
        500: '#7b746e',
        600: '#5e5a57',
        700: '#43413e',
        800: '#282726',
        900: '#0e0d0b',
    },
    // primary.300 (purple) == https://smart-swatch.netlify.app/#6366f1
    primary:
    {
        50: '#e9e7ff',
        100: '#bbb8fd',
        200: '#8b8af5',
        300: '#5b5ef0',
        400: '#372eeb',
        500: '#2d16d2',
        600: '#2a0fa4',
        700: '#230976',
        800: '#160349',
        900: '#0a001d',
    },
    // secondary.300 (gold yellow) == https://smart-swatch.netlify.app/#faa64d
    secondary:
    {
        50: '#fff2dd',
        100: '#ffd9b1',
        200: '#fdc182',
        300: '#faa64d',
        400: '#f88f22',
        500: '#de760a',
        600: '#ae5c05',
        700: '#7c4101',
        800: '#4b2600',
        900: '#1e0b00',
    },
    // tertiary.600 (pastel brown) == https://smart-swatch.netlify.app/#6e4a33
    tertiary:
    {
        50: '#ffefe6',
        100: '#ead5c7',
        200: '#d8baa7',
        300: '#c69f85',
        400: '#b58363',
        500: '#9c694a',
        600: '#7a5239',
        700: '#583b27',
        800: '#362215',
        900: '#180a00',
    },
    yellow:
    {
        50: '#fff2db',
        100: '#ffdfad',
        200: '#ffd07e',
        300: '#fdc34d',
        400: '#fcba1c',
        500: '#e3a703',
        600: '#b07600',
        700: '#7e4c00',
        800: '#4d2800',
        900: '#1c0b00',
    },
    // cyan.300 (greenish cyan) == https://smart-swatch.netlify.app/#68d8f7
    cyan:
    {
        50: '#dbffff',
        100: '#b0f2fe',
        200: '#83e4fa',
        300: '#55d3f6',
        400: '#2ed4f2',
        500: '#1ac6d9',
        600: '#09a7aa',
        700: '#007a73',
        800: '#004c43',
        900: '#001d1a',
    },
    // green.500 (mint green) == https://smart-swatch.netlify.app/#439967
    green:
    {
        50: '#e4faed',
        100: '#c5e9d4',
        200: '#a3d9ba',
        300: '#82c99f',
        400: '#5fb985',
        500: '#46a06b',
        600: '#347c53',
        700: '#24593b',
        800: '#113622',
        900: '#001406',
    },
};

export default colors;
