import { /*theme as baseTheme,*/ extendTheme } from '@chakra-ui/react';

// Global + other style overrides
import styles from './styles';
import colors from './colors';
import fonts from './fonts';
import shadows from './foundations/shadows';
// import borders from './foundations/borders';
import textStyles from './textStyles';
import layerStyles from './layerStyles';

import Container from './components/container';
import Modal from './components/Modal';
import Drawer from './components/Drawer';
import Popover from './components/popover';
import Alert from './components/alert';
import Button from './components/button';
import Text from './components/text';
import Checkbox from './components/checkbox';
import Link from './components/link';
import Heading from './components/heading';
import Card from './components/card';

/**
 * For theme directory structure, see:
 *  https://chakra-ui.com/docs/styled-system/theming/customize-theme
 *  https://chakra-ui.com/docs/styled-system/theming/component-style
 *  https://chakra-ui.com/docs/styled-system/theming/theme
 */
const overrides = {
    // Set color mode: https://chakra-ui.com/docs/styled-system/features/color-mode
    initialColorMode: 'system',
    useSystemColorMode: false,
    disableTransitionOnChange: false,
    // ...baseTheme,
    styles,
    colors,
    fonts,
    shadows,
    // borders,
    textStyles,
    layerStyles,
    // Foundational style overrides
    components: {
        Container,
        Modal,
        Drawer,
        Popover,
        Alert,
        Button,
        Text,
        Checkbox,
        Link,
        Heading,
        Card,
    },
};

export default extendTheme(overrides);
