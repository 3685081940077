import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

// From `run-if-fn`: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/utils/run-if-fn.ts
const isFunction = (value: any): value is Function =>
    typeof value === "function";

export function runIfFn<T, U>(
    valueOrFn: T | ((...fnArgs: U[]) => T),
    ...args: U[]
): T {
    return isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn;
}

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleOverlay = defineStyle((props) => {
    return {
        bg: mode('whiteAlpha.800', 'blackAlpha.500')(props),
        backdropFilter: 'auto',
        // backdropBlur: '3px',
        backdropSaturate: mode('60%', '100%')(props),
        zIndex: 'modal',
    };
});

const baseStyleDialogContainer = defineStyle((props) => {
    const { isCentered, scrollBehavior } = props;
    return {
        display: 'flex',
        zIndex: 'modal',
        justifyContent: 'center',
        alignItems: isCentered ? 'center' : 'flex-start',
        // overflow: scrollBehavior === 'inside' ? 'auto' : 'none',
    };
});

const baseStyleDialog = defineStyle((props) => {
    const { scrollBehavior } = props;
    return {
        borderRadius: '2xl',
        bg: mode('white', 'gray.900')(props),
        color: 'inherit',
        my: '16',
        zIndex: 'modal',
        maxH: scrollBehavior === 'inside' ? 'calc(100% - 7.5rem)' : undefined,
        boxShadow: mode('lg', 'dark-lg')(props),
    };
});

const baseStyleHeader = defineStyle({
    px: '6',
    py: '4',
    fontSize: 'xl',
    fontWeight: 'semibold',
});

const baseStyleCloseButton = defineStyle({
    position: 'absolute',
    top: '2',
    insetEnd: '3',
});

const baseStyleBody = defineStyle((props) => {
    const { scrollBehavior } = props;
    return {
        px: '6',
        py: '2',
        flex: '1',
        overflow: scrollBehavior === 'inside' ? 'auto' : 'none',
    };
});

const baseStyleFooter = defineStyle((props) => {
    return {
        px: '6',
        py: '4',
        roundedBottom: '2xl',
        bg: mode('gray.25', 'blackAlpha.300')(props),
    };
});

const baseStyle = definePartsStyle((props) => ({
    overlay: runIfFn(baseStyleOverlay, props),
    dialogContainer: runIfFn(baseStyleDialogContainer, props),
    dialog: runIfFn(baseStyleDialog, props),
    header: baseStyleHeader,
    closeButton: baseStyleCloseButton,
    body: runIfFn(baseStyleBody, props),
    footer: runIfFn(baseStyleFooter, props),
}));

/**
 * Since the `maxWidth` prop references theme.sizes internally,
 * we can leverage that to size our modals.
 */
function getSize(value: string) {
    if (value === 'full') {
        return definePartsStyle({
            dialog: {
                maxW: '100vw',
                minH: '$100vh',
                my: '0',
                borderRadius: '0',
            },
        });
    }
    return definePartsStyle({
        dialog: { maxW: value },
    });
}

const sizes = {
    xs: getSize('xs'),
    sm: getSize('sm'),
    md: getSize('md'),
    lg: getSize('lg'),
    xl: getSize('xl'),
    '2xl': getSize('2xl'),
    '3xl': getSize('3xl'),
    '4xl': getSize('4xl'),
    '5xl': getSize('5xl'),
    '6xl': getSize('6xl'),
    full: getSize('full'),
}

export const Modal = defineMultiStyleConfig({
    baseStyle,
    sizes,
    defaultProps: { size: 'md' },
});

export default Modal;