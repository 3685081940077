import React from 'react';
import { MatomoProvider, createInstance, useMatomo } from '@jonkoops/matomo-tracker-react';

/**
 * Better hide Matomo inside serverless functions to prevent exposing urlBase (`process.env`
 * doesn't exist on client side, only in server projects). Check the Trello item for more.
 */
const buildMatomoInstance = () => {
    const instance = createInstance({
        // urlBase: `${process.env.MATOMO_URL_BASE}/`,
        urlBase: `https://cheesebyte.matomo.cloud/`,
        siteId: 1,
        // Set user ID (email or so) per user instead of per process - use `pushInstruction` in `DefaultLayout`
        // userId: process.env.MATOMO_USER_ID,
        // trackerUrl: `${process.env.MATOMO_URL_BASE}+matomo.php`,
        // srcUrl: `${process.env.MATOMO_URL_BASE}+matomo.js`,
        // Disable global link tracking because Gatsby does dynamic routing
        linkTracking: false,
        // disabled: false,
        configurations: {
            // Any valid Matomo configuration, all below are optional
            disableCookies: true,
            setSecureCookie: false, // Only possible for HTTPS @ SiteConfig.siteUrl
            setRequestMethod: 'POST',
        },
    });

    return instance;
};

/**
 * Adds Matomo support to any component that is used as wrapped by this provider.
 * 
 * It's recommended to add this to the browser rendering only (e.g. `gatsby-browser.js`)
 * because the server-side renderer should not perform analytical behaviour and render
 * static page data only.
 * 
 * Read the documentation: https://github.com/jonkoops/matomo-tracker/
 * 
 * @param {React.ReactNode} OriginalComponent Any element that can be used in JSX.
 * 
 * @returns The input component, wrapped by @type {MatomoProvider}.
 */
export const withTracking = (element) => {
    const matomoInstance = buildMatomoInstance();
    return (
        <MatomoProvider value={matomoInstance}>
            {element}
        </MatomoProvider>
    );
};

// const composeDimension = (id, name, value) => ({
//     id,
//     name,
//     value,
// });

const composeEvent = (category, action, name, value, customDimensions) => ({
    category,
    action,
    name,
    value,
    customDimensions,
});

/**
 * Wraps an input Component that supports the `onClick` property, such as Button or `Link`, and
 * uses it to add a call to the underlying tracking system.
 * 
 * Add a property called `trackedValue` to fill in the value to log whenever the button or link
 * is activated in any way (e.g. by clicking it).
 * 
 * View this functionality as a type of middleware. But in this case, it adds tracking.
 * 
 * Supports `onClick` only for now. Future extensions could be possible.
 * 
 * @param {React.ReactElement} WrappedComponent Any React element name or object (not instantiated).
 * @param {Object} props A list of component props to pass on to the wrapped component.
 */
export const withTrackEvent = (WrappedComponent, props) => {
    // Remove temp props and compose an internal component to wrap input
    const { trackedName, trackedValue, ...rest } = props;
    const ResultWrappedComponent = () => {
        const { trackEvent } = useMatomo();

        // NOTE: 'product' should eventually be retrieved from context - see TRACKING.md
        // NOTE: Track displayScanValue via its own wrapped component on mouse up?
        // composeDimension(1, 'numberOfItems', displayScanValue),
        const dims = [];
        const event = composeEvent('product', 'onClick', trackedName, trackedValue, dims);

        const wrappedOnClick = () => {
            trackEvent(event);

            // Call the original onClick function
            // console.log('ID tracked:', trackedValue);
            if (props.onClick) {
                props.onClick();
            }
        };

        // Don't do anything component specific here. It needs to work with any component
        // that needs to (eventually) track something. Just wrap the requested event and
        // pass along properties. That's it. Keep it simple.
        return (<WrappedComponent
            {...rest}
            onClick={wrappedOnClick}>
            {props.children}
        </WrappedComponent>);
    };

    // Requirements fulfilled: `onClick` is wrapped and tracks `props.id`. For
    // future usage and extensions this requires a bit more work, but please
    // do keep it all local.
    return (<ResultWrappedComponent />);
};

export const useTrackPageView = () => {
    /* eslint-disable react-hooks/exhaustive-deps */
    const { enableLinkTracking, trackPageView } = useMatomo();

    enableLinkTracking();
    React.useEffect(() => trackPageView(), []);

    return [];
};
