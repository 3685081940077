import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { mode } from '@chakra-ui/theme-tools';

/**
 * You can also use the more specific type for
 * a single part component: ComponentSingleStyleConfig
 * 
 * See: https://chakra-ui.com/docs/styled-system/theming/component-style
 */
const Button: ComponentStyleConfig = {
    baseStyle: (props) => ({
        fontWeight: 'light',
        cursor: 'pointer',
        textDecorationLine: 'none',
        rounded: 'lg',
        boxShadow: 'xl',
        color: 'white',
        bg: 'primary.300',
        // borderBottomWidth: '0.20em',
        _hover: {
            bgColor: 'primary.400',
            color: 'white',
            boxShadow: 'sm',
        },
        _disabled: {
            opacity: 1,
            color: mode('blackAlpha.400', 'whiteAlpha.400')(props),
            bgColor: mode('transparent', 'transparent')(props),
            borderColor: mode('whiteAlpha.500', 'whiteAlpha.100')(props),
            borderWidth: 2,
            cursor: 'default',
            boxShadow: 'none',
        },
    }),
    sizes: {
        base: {
            p: 0,
        },
        sm: {
            fontSize: 'sm',
            p: 5,
        },
        md: {
            fontSize: 'md',
            p: 6,
        },
        xl: {
            fontSize: 'xl',
            p: 8,
        },
    },
    variants: {
        'base-dark': (props) => ({
            bg: mode('primary.300', 'white')(props),
            color: mode('white', 'primary.500')(props),
            _hover: {
                bg: mode('primary.400', 'primary.600')(props),
                color: mode('white', 'white')(props),
            },
        }),
        ghost: (props) => ({
            color: mode('gray.600', 'white')(props),
            bg: mode('white', 'whiteAlpha.200')(props),
            _hover: {
                bg: 'primary.400',
            },
            _pressed: {
                // Doesn't work, why?
                bgColor: 'primary.500',
            }
        }),
        white: (props) => ({
            color: mode('gray.600', 'primary.300')(props),
            bg: mode('white', 'white')(props),
            _hover: {
                bg: 'primary.400',
            },
            _pressed: {
                bgColor: 'primary.300',
            }
        }),
        navlink: (props) => ({
            p: 2,
            fontSize: 'sm',
            fontWeight: 500,
            color: mode('gray.600', 'gray.200')(props),
            bgColor: 'unset',
            boxShadow: 0,
            _hover: {
                color: mode('primary.300', 'white')(props),
                bgColor: 'unset',
                boxShadow: 0,
            },
        }),
        'navlink-dark': (props) => ({
            rounded: 'lg',
            p: 2,
            fontSize: 'sm',
            fontWeight: 'extrabold',
            color: mode('white', 'white')(props),
            bgColor: 'primary.400',
            _hover: {
                color: mode('primary.50', 'primary.50')(props),
                bgColor: 'primary.500',
            },
        }),
        unstyled: {
            boxShadow: 0,
            whiteSpace: 'unset',
            _hover: {
                bg: 'unset',
                boxShadow: 'none',
            },
        }
    },
    defaultProps: {
        size: 'md',
        variant: 'base',
    },
};

export default Button;
