import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { mode } from '@chakra-ui/theme-tools';

/**
 * You can also use the more specific type for
 * a single part component: ComponentSingleStyleConfig
 * 
 * WARNING: Seems to be suffering from the same issues as Heading.
 * 
 * See: https://chakra-ui.com/docs/styled-system/theming/component-style
 */
const Text: ComponentStyleConfig = {
    baseStyle: (props) => ({
        color: mode('gray.600', 'gray.100')(props),
        fontWeight: 'light',
        whiteSpace: 'pre-line',
    }),
    sizes: {
        xs: {
            fontSize: 'small',
        },
        md: {
            fontSize: 'medium',
        },
        lg: {
            fontSize: 'large',
        },
        xl: {
            fontSize: 'x-large',
        },
        '2xl': {
            fontSize: 'xx-large',
        }
    },
    variants: {
        muted: (props) => ({
            color: mode('gray.300', 'whiteAlpha.500')(props),
        })
    },
    defaultProps: {
        // size: 'md',
    }
};

export default Text;
