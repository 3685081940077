import React, { useReducer } from 'react';

import { Flex, Box, Tag, useTheme } from '@chakra-ui/react';
import { motion, useMotionValue } from 'framer-motion';

import { VectorBackground, Icon } from 'components/view';
import { pickVisibleColor } from 'utils/helper/color';

/**
 * Shows details of any product, service or other type of case that needs a quick
 * overview of what a user would be presented with after clicking on the button.
 */
const TaggedPreview = ({ title, icon, tags, colors, ...rest }) => {
    // From: https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
    // eslint-disable-next-line
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const currentScaleInMotion = useMotionValue(1);

    const theme = useTheme();
    const textColor = pickVisibleColor(theme, colors?.length > 0 ? colors[0] : 'black');

    const updateScale = (newScale) => {
        // Skip browsers like Firefox because updating would be slow: https://github.com/framer/motion/issues/441
        currentScaleInMotion.current = newScale;
        forceUpdate();

        // animate(currentScaleInMotion, newScale, {
        //     onUpdate: latest => forceUpdate()
        // });
    };

    return (<VectorBackground
        p={3}
        pattern="plaid-stripe"
        rounded="xl"
        {...rest}
        display="flex"
        bgColor="unset"
        colors={colors}
        scale={currentScaleInMotion.current}
        as={motion.div}
        onHoverStart={() => updateScale(0.75)}
        onHoverEnd={() => updateScale(1.0)}>
        <Flex
            direction="column"
            alignItems="stretch"
            alignContent="stretch"
            justify="space-between">
            <div>
                <Icon
                    name={icon}
                    size="sm"
                    color="white" />
                <Box
                    textStyle="display-3"
                    style={{ color: textColor }}>
                    {title}
                </Box>
            </div>
            <div>
                {tags?.map((x, id) =>
                    <Tag
                        key={id}
                        m={0.5}
                        size="sm"
                        bgColor="white"
                        color="black">{x}</Tag>
                )}
            </div>
        </Flex>
    </VectorBackground>);
};

export default TaggedPreview;
