import React from 'react';
import { Container, Image } from '@chakra-ui/react';

import { SiteHero } from 'components/view';
import { SwitchComponent, ForceRatio } from 'components/layout';

const MobileFooter = (props) => <Container
    maxW="container.xl"
    {...props}>
    <ForceRatio
        maxW="full"
        ratio={2.58}>
        <Image
            src="/images/logo/logo-cheese-four-row.png"
            maxW="full"
            rounded="2xl" />
    </ForceRatio>
</Container>;

const PageFooter = () => <SwitchComponent
    first={MobileFooter}
    second={SiteHero} />;

export default PageFooter;
