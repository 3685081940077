import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter } from '@chakra-ui/react';

import { DetailPanel, DetailFooter } from '.';

const DetailOnDesktop = ({ itemInfo, disclosure }) => {
    /* eslint-disable jsx-a11y/no-autofocus */
    const { onClose, isOpen } = disclosure;
    return (<>
        <Modal
            isCentered
            size="3xl"
            onClose={onClose}
            isOpen={isOpen}
            scrollBehavior="inside"
            /**
             * Disable to keep the UI consistent - it's not nice to force
             * the user to search for the top area of the dialog. The default
             * option would be to scroll downwards when opening a dialog.
             */
            autoFocus={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody >
                    <DetailPanel info={itemInfo} />
                </ModalBody>
                <ModalFooter justifyContent="space-between">
                    <DetailFooter onClose={onClose} />
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>);
};

export default DetailOnDesktop;
