import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import { VStack, Text, Image } from '@chakra-ui/react';
import { ForceRatio } from 'components/layout';

const MissingImageData = () => {
    const { t } = useTranslation();
    const maxImageWidth = "12em";

    return (<>
        <ForceRatio maxW={maxImageWidth} ratio={1} alignSelf="center">
            <StaticImage
                src="./../images/logo-cheesebyte-512px.png"
                alt="Cheesebyte Logo"
                placeholder="blurred"
                width={512}
                height={512} />
        </ForceRatio>
        <Text
            variant="muted"
            size="xs"
            alignSelf="center">
            {t('detail_panel_extra_info_body')}
        </Text>
    </>)
};

const StackInfoExtra = ({ info, width }) => {
    const { image, image_public, copyright } = info;

    // Choose between a pre-processed image (e.g. blurred up via Gatsby plugin and loaded
    // once that part of the page is visible) or a public image URL, which is used as a
    // backup for special image formats such as GIF. These images aren't supported by
    // the pre-processing process from the Gatsby plugin.
    const hasImage = image?.length > 0;
    const hasImagePublic = image_public?.length > 0;

    // Center on low resolution to keep a consistent look
    const stackAlignSelf = {
        base: 'center',
        lg: 'start',
    };

    return (<VStack
        alignSelf={stackAlignSelf}
        align="start"
        spacing={6}
        w={width}>
        {hasImage && image.map((x, id) => (
            <Image
                as={GatsbyImage}
                key={id}
                rounded="xl"
                alt={`Preview image #${id}`}
                image={getImage(x)}
                ratio={1.75} />
        ))}

        {hasImagePublic && image_public.map((x, id) => (
            <Image
                src={x}
                key={id}
                rounded="xl"
                alt={`Preview image #${id}`} />
        ))}

        {/* No image data available; show a message about this to the user */}
        {(!hasImage && !hasImagePublic) && <MissingImageData />}

        {/* Copyright message, if it exists */}
        {copyright && <Text
            variant="muted"
            size="xs"
            alignSelf="center">
            &copy; {copyright}
        </Text>}
    </VStack>);
};

export default StackInfoExtra;
