import React, { useState } from 'react';
import { useI18next, Trans } from 'gatsby-plugin-react-i18next';
import { SiteConfig } from 'utils/config';

import { chakra, Container, Center, Heading, Text, useColorModeValue } from '@chakra-ui/react';
import { RangeSlider, RangeSliderTrack, RangeSliderThumb, RangeSliderFilledTrack } from '@chakra-ui/react';

import { LinkTranslate } from 'components/navigation';
import { SlideScroller } from 'components/layout';
import { ThumbArrows } from 'components/visual';
import { PlanColumn } from './components';

const numberOfScans = [
    500,
    1000,
    2500,
    5000,
    7500,
    10000,
    25000,
    50000,
    75000,
    100000,
];

// One absolute baseprice (required to minimally cover all costs for the specific plan) - all in EUR for now
const basePricesByPlanId = {
    '0dcd9975-daa0-4fd7-9eb7-7cb70b882419': 0.10, // Basic
    'f7fe4386-f87e-4efe-9a33-a7a05c0b1e54': 0.15, // Advanced
    'cdbe1afe-f17a-4f30-8d5d-466cbc154227': 0.18, // Expert
};

// In the future (and an actual implementation), this data and calculation of it should
// be performed in a microservice. Also helps with dynamic pricing + split testing.
const calcSubscriptionPrice = (planId, scanLevel) => {
    const invertedScanLevel = numberOfScans.length - scanLevel;
    const fixedMarginPrice = 0.02;

    // Freebies for a limited amount of scans in basic plan
    if (scanLevel === 0 && planId === '0dcd9975-daa0-4fd7-9eb7-7cb70b882419') {
        return 0;
    }

    return basePricesByPlanId[planId] + (invertedScanLevel * fixedMarginPrice);
}

/**
 * Displays pricing strategies in a well known visual and user adjustable way. Can be set up
 * to represent pricing per user, but also per product or per usage of a product.
 * 
 * All text is currently "hardcoded" (in content MDX + locale JSON files) to "scans" instead
 * of the more generic term "documents" or something else. After introducing more product
 * pages it should be useful to add the document name to content MDX and use the following
 * library to pluralise the name where it's needed: https://github.com/plurals/pluralize
 */
const PricingSection = ({ onPlanClick, plans, ...rest }) => {
    const { language, t } = useI18next();
    const [currentScanId, setCurrentScanId] = useState(0);

    const displayScanValue = numberOfScans[currentScanId];
    const scanPriceColor = useColorModeValue('primary.500', 'white');

    return (<>
        <Container
            as="section"
            p={0}
            mt={12}
            maxW="container.xl"
            {...rest}>
            <chakra.h1 align="center">{t('product_pricing_title')}</chakra.h1>
            <Text variant="muted" size="lg" px={3} align="center">{t('product_pricing_body')}</Text>
            <Heading
                size="4xl"
                fontWeight="extrabold"
                color={scanPriceColor}
                align="center">
                {displayScanValue.toLocaleString(language)}
            </Heading>
            <Center mt={3}>
                <RangeSlider
                    onChange={(value) => setCurrentScanId(value[0])}
                    defaultValue={[currentScanId]}
                    width="35vw"
                    min={0}
                    max={numberOfScans.length - 1}>
                    <RangeSliderTrack bg={useColorModeValue('primary.50', 'whiteAlpha.200')}>
                        <RangeSliderFilledTrack bg="primary.400" />
                    </RangeSliderTrack>
                    <RangeSliderThumb boxSize={9} bg="primary.300" index={0}>
                        <ThumbArrows />
                    </RangeSliderThumb>
                </RangeSlider>
            </Center>
        </Container>
        <SlideScroller showButtons={false} gap={12} px={16} pb={20}>
            {plans && plans.map((singlePlan, id) =>
                <PlanColumn
                    key={id}
                    onChooseClick={() => onPlanClick(singlePlan.id)}
                    price={calcSubscriptionPrice(singlePlan.id, currentScanId)}
                    {...singlePlan} />
            )}
        </SlideScroller>
        <Container maxW="container.xl" height={0}>
            {/* Relative text to lift it up and hover on top of the flex component above
                this one. Otherwise, the horizontal overflow above would make this text
                look very much out of place (in visual terms). A hack, but looks much better. */}
            <Text
                size="md"
                align="center"
                my={0}
                pos="relative"
                top="-5rem"
                pointerEvents="none">
                <Trans i18nKey="product_pricing_subtitle" t={t} components={[
                    <LinkTranslate
                        to={`mailto:${SiteConfig.siteUseEmailAddress}?subject=${t('product_pricing_plan_email_subject')}`}
                        isExternal={true} />
                ]} />
            </Text>
        </Container>
    </>);
}

export default PricingSection;
