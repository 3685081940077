import React from 'react';

import { IconButton, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { Icon as BxIcon } from 'components/view';

const ThemeToggle = ({ isRound, variant }) => {
    const { toggleColorMode: toggleMode } = useColorMode();
    const toggleIcon = useColorModeValue('bx-sun', 'bx-moon');

    return (
        <IconButton
            icon={<BxIcon name={toggleIcon} size="xs" />}
            variant={variant || useColorModeValue('ghost', 'white')}
            aria-label="Toggle Theme"
            onClick={toggleMode}
            isRound={isRound}
            rounded={isRound && 'full'} />
    )
};

export default ThemeToggle;
