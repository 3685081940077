import type { ComponentStyleConfig } from '@chakra-ui/theme';

/**
 * You can also use the more specific type for
 * a single part component: ComponentSingleStyleConfig
 * 
 * See: https://chakra-ui.com/docs/styled-system/theming/component-style
 */
const Checkbox: ComponentStyleConfig = {
    parts: ['control', 'icon'],
    baseStyle: {
        // fontWeight: 'light',
        // borderRadius: 'md',
        // rounded: 'lg',
        control: {
            bg: 'white',
            borderColor: 'gray.200',
            rounded: '4',
            color: 'white',
            _checked: {
                bg: 'primary.300',
                borderColor: 'primary.300',
                _hover: {
                    bg: 'primary.400',
                    borderColor: 'primary.400',
                }
            },
            _active: {
                bg: 'primary.400',
                borderColor: 'primary.400',
            }
        },
        icon: {
            color: 'white',
        }
    },
    sizes: {
    },
    variants: {
        base: {
        },
    },
    defaultProps: {
        size: 'md',
        // variant: 'base',
    },
};

export default Checkbox;
