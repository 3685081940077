import React from 'react';

import { Popover, PopoverTrigger, PopoverContent, PopoverArrow } from '@chakra-ui/react';
import { Box, Button, Icon, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

/**
 * A menu item drawn as a `Button` component with automatic trigger to make
 * a `PopoverContent` component fly out after hovering over it with a pointing
 * device.
 * 
 * `children` is the standard React prop and can be used to add at least one
 * component with menu related sub-items.
 */
const NavigationMenuItem = ({ label, buttonVariant, showDownIcon, isRound, children }) => {
    // Unfortunately Popover cannot be styled, hence the usage of gutter and
    // arrowSize here (which appear to be passed down to the Popper library).
    const { onOpen, onClose, isOpen } = useDisclosure()
    return (
        <Box key={label}>
            <Popover
                trigger="hover"
                placement="bottom-start"
                isLazy={true}
                // openDelay={0}
                // closeDelay={100}
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                gutter={0}
                arrowSize={16}>
                <PopoverTrigger>
                    <Button
                        rounded={isRound && 'full'}
                        variant={buttonVariant || 'navlink'}>
                        {label}
                        {showDownIcon && children && <Icon
                            as={ChevronDownIcon}
                            transition="all .25s ease-out"
                            transform={isOpen ? 'rotate(180deg)' : ''}
                            color={useColorModeValue('gray.100', 'gray.600')}
                            w={6}
                            h={6} />}
                    </Button>
                </PopoverTrigger>

                {children && (
                    <PopoverContent
                        display={isOpen ? 'block' : 'none'}
                        width="fit-content">
                        <PopoverArrow />
                        {children}
                    </PopoverContent>)}
            </Popover>
        </Box>
    );
};

export default NavigationMenuItem;
