import { useTheme } from '@chakra-ui/react';
import { getColor, darken, lighten } from "@chakra-ui/theme-tools";

/**
 * Creates a new array with colors lighter and darker based on
 * either the Chakra UI semantic color format or hexadecimal.
 * 
 * Use `useSemanticColor` to get everything in semantic format.
 * 
 * @returns Mapped color array.
 */
export const useColorMapping = (mainColor) => {
    const theme = useTheme();

    // NOTE: One way to improve this visually would be to add
    //       a color with its hue shifted 35 or 45 deg.
    const mappedColors = [
        darken(mainColor, 3)(theme),
        lighten(mainColor, 3)(theme),
        lighten(mainColor, 4)(theme),
        mainColor,
        lighten(mainColor, 7)(theme),
    ];

    return [mappedColors];
};

/**
 * Converts any color in either Chakra UI or hexadecimal format
 * into Chakra UI semantic colors (e.g. primary.xxx).
 * 
 * @param {Array} inputColors Any color in hexademical form to convert.
 */
export const useSemanticColor = (inputColors) => {
    const theme = useTheme();
    const convertedColors = inputColors.map(c => getColor(theme, c));

    return [convertedColors];
}
