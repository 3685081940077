import React from 'react';
import PropTypes from 'prop-types';

import { Container, Box, Flex, Text } from '@chakra-ui/react';
import { GridListWithHeading } from 'components/layout';

/**
 * Displays to the user why he/she should use the product through a quick
 * overview of selling points and combines it with a preview to show a simple
 * demo or the difference compared to existing products it competes with.
 */
const FeatureSection = ({ heading, description, items, demoComponent, demoText, ...rest }) => {
    const convertedListItems = items?.map((item, id) => ({
        id, ...item,
    }));

    return (
        <Container
            as="section"
            maxW="container.xl"
            py={6}
            {...rest}>
            <Flex direction={{ base: 'column', lg: 'row' }}>
                <Box
                    mt={12}
                    rounded="3xl"
                    maxW={{ base: 'full', lg: '26em' }}
                    align="center"
                    direction="column">
                    {demoComponent}
                    <Text variant="muted" my={3}>{demoText}</Text>
                </Box>

                <GridListWithHeading
                    heading={heading}
                    description={description}
                    items={convertedListItems} />
            </Flex>
        </Container>
    );
};

FeatureSection.propTypes = {
    heading: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
    })),
};

export default FeatureSection;
