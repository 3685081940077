import React from 'react';
import { Flex, HStack } from '@chakra-ui/react';

import { VectorBackground } from 'components/view';
import { dripHorizontalProps, dripVerticalProps } from 'components/visual/Drip';

const DripLayoutHorizontal = ({ children, ...rest }) => <VectorBackground
    display="flex"
    direction="row"
    pattern="plaid"
    scale={3}
    _before={{
        content: `""`,
        ...dripVerticalProps,
    }}>
    <HStack
        justifyContent="space-between"
        gap={{ base: 1, md: 3 }}
        me="4em" // hack: subtract margin of 4em from vertical drip image
        w="full"
        {...rest}>
        <HStack
            justifyContent="center"
            gap={{ base: 1, md: 3 }}
            py={{ base: 3, md: 6 }}
            w="full">
            {children}
        </HStack>
    </HStack>
</VectorBackground>;

const DripLayoutVertical = ({ children, ...rest }) => <VectorBackground
    display="flex"
    flexDirection="column"
    pattern="plaid"
    scale={6}
    _before={{
        content: `""`,
        ...dripHorizontalProps,
    }}>
    <Flex
        width="full"
        direction="column"
        align="center"
        justify="center"
        mb={6}
        {...rest}>
        {children}
    </Flex>
</VectorBackground>;

const DripBackground = ({ orientation, children, ...rest }) => {
    const pattern = {
        'vertical': DripLayoutVertical,
        'horizontal': DripLayoutHorizontal,
    };

    const Component = pattern[orientation];
    return <Component {...rest}>{children}</Component>;
}

export default DripBackground;
