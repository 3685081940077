import React from 'react';
import PropTypes from 'prop-types';
import url from 'url';

import FontMeta from './FontMeta';
import AssetMeta from './AssetMeta';
import WebsiteMeta from './WebsiteMeta';

import { SiteConfig } from 'utils/config';

/**
 * MetaData will generate all relevant meta data information, including
 * preloaded assets (e.g. fonts) and future usage of JSON-LD schemas
 * for SEO purposes.
 */
const MetaData = ({ location }) => {
    const websiteProps = {
        title: SiteConfig.siteTitle,
        description: SiteConfig.siteDescription,
        canonical: url.resolve(SiteConfig.siteUrl, location.pathname),
    };

    return (<>
        <WebsiteMeta {...websiteProps} />
        <FontMeta />
        <AssetMeta location={location} />
    </>);
};

MetaData.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default MetaData;
