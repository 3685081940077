require('react');

const { withChakra } = require('utils/hoc/chakra');
// const { withTracking } = require('utils/hoc/tracking');

exports.wrapPageElement = ({ element }) => {
    return withChakra(element, false);
    // return withTracking(
    //     withChakra(element, false)
    // );
}

/*exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    // Disabled because automatic update of scroll position is percieved as annoying
    // const currentPosition = getSavedScrollPosition(location);
    // const queriedPosition = getSavedScrollPosition({ pathname: `/random` });

    // window.scrollTo(...(currentPosition || [0, 0]));

    return false;
}*/
