import React from 'react';
import { Drawer, DrawerOverlay, DrawerContent, DrawerBody, DrawerFooter } from '@chakra-ui/react';

import isMobile from 'ismobilejs';
import { isSsr } from 'utils/helper/site';
import { DetailPanel, DetailFooter } from '.';

const DetailOnMobile = ({ itemInfo, disclosure }) => {
    /* eslint-disable jsx-a11y/no-autofocus */
    const { onClose, isOpen } = disclosure;
    const userAgent = !isSsr() ? window?.navigator.userAgent : 'No user agent available for this environment';
    const isPhone = isMobile(userAgent)?.phone;

    return (<Drawer
        isFullHeight={!isPhone}
        placement="bottom"
        closeOnOverlayClick={true}
        onClose={onClose}
        isOpen={isOpen}
        /**
         * Disable to keep the UI consistent - it's not nice to make the user
         * search for the top area of the dialog for some and sometimes (if
         * there's no reference link) not.
         */
        autoFocus={false}>
        <DrawerOverlay />
        <DrawerContent
            // Force max height for Chrome @ Android, otherwise Chakra UI will
            // show the content with an overlapping input field for browser urls.
            maxH={isPhone && '92vh'}
            scrollBehavior="inside"
            roundedTop="2xl">
            <DrawerBody px={3}>
                <DetailPanel info={itemInfo} />
            </DrawerBody>
            <DrawerFooter justifyContent="space-between">
                <DetailFooter onClose={onClose} />
            </DrawerFooter>
        </DrawerContent>
    </Drawer>);
};

export default DetailOnMobile;
