import React from 'react';

// import { useInView } from 'framer-motion';
import { Box, Image as ChakraImage } from '@chakra-ui/react';

/**
 * Basically a Chakra UI `Image` component, but with a small wrapper to handle
 * automatic fade-ins once the image is visible in the current view. This is
 * useful for vector images because Gatsby does not handle these, while vector
 * images can still be seen popping up all over the place.
 * 
 * Doesn't look very professional, so this is used instead.
 * 
 * More info: https://www.framer.com/docs/use-in-view/
 */
const Image = (props) => {
    // Funny thing is: this should start transitioning once an image
    // was loaded. We're not doing that here because vector images are
    // usually pretty quick to load.
    // const ref = React.useRef(null);
    // const isInView = useInView(ref, { once: true, amount: 'some' });

    // const style = {
    //     ...props.style,
    //     opacity: isInView ? 1 : 0,
    //     transition: 'all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
    // };

    return (<Box
        // ref={ref}
        w="full"
        h="full"
        /*style={style}*/>
        <ChakraImage {...props} />
    </Box>);
};

export default Image;
