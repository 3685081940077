import React from 'react';
import PropTypes from 'prop-types';

import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { chakra, Container, Button, Text, Highlight } from '@chakra-ui/react';

import { HeroLayout, ForceRatio } from 'components/layout';
import { VectorBackground, Icon, Image } from 'components/view';
import { Link } from 'components/navigation';

/**
 * Banner with dynamic product image, description and CTA button.
 */
const HeroSection = ({ toLink, title, description, image, ...rest }) => {
    const { t } = useTranslation();

    const buttonCmp = <>
        <Button
            as={Link}
            to={toLink}
            rounded="lg"
            color="black"
            bg="white"
            boxShadow="lg"
            leftIcon={<Icon name="bx-right-arrow" size="xs" color="black" />}
            _hover={{ color: 'white', bgColor: 'primary.300' }}>
            {t('product_hero_button_start')}
        </Button>
    </>;

    const heroImage = getImage(image);
    const imageCmp = heroImage && <ForceRatio
        display={{ base: 'none', lg: 'block' }}
        maxW="32em"
        ratio={heroImage.width / heroImage.height}>
        <Image
            as={GatsbyImage}
            rounded="xl"
            alt="Hero Image"
            image={heroImage}
            style={{ mixBlendMode: 'luminosity' }}
            ratio={heroImage.width / heroImage.height} />
    </ForceRatio>;

    const descriptionCmp = <>
        <chakra.h1
            fontSize={{
                base: '5xl',
                lg: '7xl',
            }}
            lineHeight="1.1em"
            color="white">
            {title}
        </chakra.h1>
        <Text color="black" size="lg">
            {<Highlight
                query={[]}
                styles={{
                    px: '2',
                    py: '1',
                    rounded: 'lg',
                    color: 'white',
                    bg: 'primary.500',
                }}>
                {description || ''}
            </Highlight>}
        </Text>
    </>;

    return (<VectorBackground
        pattern="zigzag"
        scale={10}
        colors={[
            'secondary.300',
            '#fab369',
        ]}>
        <Container
            as="section"
            maxW="container.xl"
            {...rest}
            py={12}>
            <HeroLayout
                pt={{ base: 0, md: '3.5rem' }}
                align="center"
                descriptionComponent={descriptionCmp}
                callToActionComponent={buttonCmp}
                imageComponent={imageCmp} />
        </Container>
    </VectorBackground>);
};

HeroSection.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.object,
};

export default HeroSection;
