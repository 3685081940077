import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Box, Container, SimpleGrid, Stack, Image, Text, useColorModeValue } from '@chakra-ui/react';

import { Icon } from 'components/view';
import { Link } from 'components/navigation';
import { CollapseLayout, SwitchComponent, ForceRatio } from 'components/layout';
import { SiteConfig } from 'utils/config';

const ListRow = ({ id, title, children }) => {
    const mobileLayout = () => <Container maxW="container.xl">
        <CollapseLayout
            title={title}
            hideCollapse={false}
            defaultIsOpen={id === 0 ? true : false}>
            {children}
        </CollapseLayout>
    </Container>;

    const desktopLayout = () => <Container maxW="container.xl">
        <CollapseLayout
            title={title}
            hideCollapse={true}
            defaultIsOpen={true}>
            {children}
        </CollapseLayout>
    </Container>;

    return <SwitchComponent
        first={mobileLayout}
        second={desktopLayout} />
}

/**
 * Fully implemented footer with everything required to be useful for an end-user.
 */
const Footer = () => {
    // NOTE: For future reference > usage of {visibility="hidden"} can be removed by using Wrap/WrapItem below instead (see mdx.js)
    const { language, t } = useI18next();
    return (
        <Box as="footer">
            <Container as={Stack} maxW="6xl" pt={6}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={8}>
                    <ListRow title={t('title_contact')} id={0}>
                        <Stack direction="row" align="center" spacing={2}><Icon size="xs" name="bx-map" /><Text>Keurenplein 4 (A1800)</Text></Stack>
                        <Stack direction="row" align="center" spacing={2}><Icon size="xs" visibility="hidden" /><Text>1069 CD Amsterdam</Text></Stack>
                        {language !== 'nl' && <Stack direction="row" align="center" spacing={2}><Icon size="xs" visibility="hidden" /><Text>{t('footer_office_address_country')}</Text></Stack>}
                        <Stack direction="row" align="center" spacing={2}><Icon size="xs" name="bx-envelope" /><Link to={`mailto:${SiteConfig.siteUseEmailAddress}?subject=Info`}>{SiteConfig.siteUseEmailAddress}</Link></Stack>
                        <Stack direction="row" align="center" spacing={2}><Icon size="xs" name="bx-building" /><Text>83647392</Text></Stack>
                        {/* <Stack direction="row" align="center" spacing={2}><Icon name="bx-phone-call" /><Text>+31 (0)20 36 99 884</Text></Stack> */}
                    </ListRow>
                    <ListRow title={t('title_about')} id={1}>
                        <Link to="/info/business">{t('title_business')}</Link>
                        <Link to="/info/affiliates">{t('title_affiliates')}</Link>
                    </ListRow>
                    <ListRow title={t('title_legal')} id={2}>
                        <Link to="/info/cookie-policy">{t('title_cookie_policy')}</Link>
                        <Link to="/info/privacy-policy">{t('title_privacy_policy')}</Link>
                        <Link to="/info/terms-and-conditions">{t('title_terms_conditions')}</Link>
                    </ListRow>
                    <ListRow title={t('title_social')} id={3}>
                        <Stack direction="row" align="center" spacing={2}><Icon size="xs" name="bxl-facebook" /><Link to="https://www.fb.me/cheesebyte">Facebook</Link></Stack>
                        <Stack direction="row" align="center" spacing={2}><Icon size="xs" name="bxl-twitter" /><Link to="https://www.twitter.com/cheesebytecode">Twitter</Link></Stack>
                        <Stack direction="row" align="center" spacing={2}><Icon size="xs" name="bxl-linkedin" /><Link to="https://www.linkedin.com/company/cheesebyte/">LinkedIn</Link></Stack>
                        <Stack direction="row" align="center" spacing={2}><Icon size="xs" name="bxl-instagram" /><Link to="https://www.instagram.com/cheesebytecode/">Instagram</Link></Stack>
                    </ListRow>
                </SimpleGrid>
            </Container>
            <Stack
                direction="row"
                justify="center"
                position="relative"
                top={4}
                py={0}
                pt={{ base: 6, md: 0 }} >
                <ForceRatio maxW="5em" ratio={1}>
                    <Image
                        src="/site/android-chrome-192x192.png"
                        alt="Cheesebyte Logo"
                        w="full"
                        h="full" />
                </ForceRatio>
            </Stack>
            <Stack
                direction={{ base: 'column', md: 'row' }}
                justify="center"
                py={3}
                pt={6}
                bg={useColorModeValue('gray.25', 'blackAlpha.300')}>
                <Text m={0} fontSize="sm" textAlign="center">
                    &copy; 2021 - {new Date().getFullYear()}&nbsp;&nbsp;<Link to="/">Cheesebyte</Link>
                </Text>
                <Text m={0} fontSize="sm" textAlign="center">
                    {t('footer_all_rights_reserved')}
                </Text>
            </Stack>
        </Box>
    );
};

export default Footer;
