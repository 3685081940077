import React from 'react';

import { ReactCompareSlider } from 'react-compare-slider';
import { Box, useBreakpointValue } from '@chakra-ui/react';

import { VectorBackground } from 'components/view';
import { ThumbArrows } from 'components/visual';

// The only way to make the handle fill the parent Box is to duplicate
// ReactCompareSlider and allow it to draw outside of the child borders.
const DragHandle = () => <Box
    width={0.5}
    height="full"
    bg="secondary.300"
    sx={{
        display: 'grid',
        placeContent: 'center',
    }}>
    <Box
        cursor="pointer"
        display="flex"
        width={9}
        height={9}
        rounded="3xl"
        alignItems="center"
        justifyContent="center"
        bg="secondary.300">
        <ThumbArrows />
    </Box>
</Box>;

const ImageWrapper = ({ children }) => {
    return (<VectorBackground
        as={Box}
        pattern="plaid"
        scale={4}
        w="full"
        h="full"
        rounded="3xl">
        {children}
    </VectorBackground>);
};

const FeatureComparison = ({ imageBefore, imageAfter }) => {
    // Chakra UI's `rounded` prop doesn't work with ReactCompareSlider
    const style = { borderRadius: '1.25em', width: '100%' };
    const onlyHandleDraggable = useBreakpointValue({ base: true, lg: false });

    return (
        <Box
            display="flex"
            align="center"
            p={0}
            w="full"
            h="full"
            rounded="3xl">
            <ReactCompareSlider
                style={style}
                onlyHandleDraggable={onlyHandleDraggable}
                handle={<DragHandle />}
                itemOne={<ImageWrapper>{imageBefore}</ImageWrapper>}
                itemTwo={<ImageWrapper>{imageAfter}</ImageWrapper>} />
        </Box>
    );
}

export default FeatureComparison;
