import React from 'react';
import { Helmet } from 'react-helmet';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { isSsr } from 'utils/helper/site';
import { SiteConfig } from 'utils/config';

const AssetMeta = ({ location }) => {
    /* eslint-disable react-hooks/exhaustive-deps */
    const { language } = useI18next();
    const { siteUrl } = SiteConfig;

    /**
     * Using the base tag here isn't necessarily great, but required for correct urls when
     * the route is pointed towards something else than the main domain. Resources like
     * images wouldn't load due to the location origin that's automatically used being
     * created incorrectly.
     *
     * Default to 'siteUrl' in the site config to satisfy SSR (rendering via Node.js
     * instead of the browser - on the server side).
     *
     * Read this: https://www.gatsbyjs.com/docs/how-to/previews-deploys-hosting/path-prefix/
     */
    const urlBase = (isSsr() ? siteUrl : location.origin) + '/';
    return (<Helmet>
        <base href={urlBase}></base>
        <html lang={language} />
    </Helmet>);
};

export default AssetMeta;
