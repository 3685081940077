import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { chakra, Box, Container, Text, Link as ChakraLink, useColorModeValue, useDisclosure, useBreakpointValue } from '@chakra-ui/react';

import { SlideScroller } from 'components/layout';
import { TaggedPreview } from 'components/view';

import { useColorMapping } from 'utils/hook/useColorMapping';
import { withTrackEvent } from 'utils/hoc/tracking';

import { DetailOnMobile, DetailOnDesktop } from './components';

const detailItemColors = [
    'secondary.300',
    'gray.500',
    'tertiary.600',
    'primary.300',
];

const PastExperienceButton = ({ id, item, onClick }) => {
    const [mappedColors] = useColorMapping(detailItemColors[id % detailItemColors.length])
    return (<ChakraLink
        onClick={onClick}
        variant="unstyled">
        <TaggedPreview
            w="12em"
            h="12em"
            colors={mappedColors}
            {...item} />
    </ChakraLink>);
};

const TrackedPastExperienceButton = (props) => {
    return withTrackEvent(PastExperienceButton, {
        trackedName: props.trackedName,
        trackedValue: props.trackedValue,
        ...props,
    });
};

const PastExperienceSection = ({ items }) => {
    const { t } = useTranslation();
    const [currentDetailId, setCurrentDetailId] = React.useState(0);

    const handleDetailId = (id) => {
        setCurrentDetailId(id);
        disclosure.onOpen();
    };

    const disclosure = useDisclosure();
    const DetailComponent = useBreakpointValue({
        base: DetailOnMobile,
        md: DetailOnDesktop,
    });

    return (<Box
        as="section"
        bgColor={useColorModeValue('gray.25', 'whiteAlpha.50')}
        py={3}
        pb={6}>
        <DetailComponent
            itemInfo={items && items[currentDetailId]}
            disclosure={disclosure} />
        <Container
            maxW="container.xl"
            my={3}>
            <chakra.h1
                align="center"
                my={3}>
                {t('past_experience_title')}
            </chakra.h1>
            <Text
                size="lg"
                align="center"
                m="auto"
                maxW="container.sm">
                {t('past_experience_body')}
            </Text>
            <SlideScroller mt={6}>
                {items?.map((detailItem, index) => <TrackedPastExperienceButton
                    trackedName="serviceItem"
                    trackedValue={index}
                    key={index}
                    id={index}
                    item={detailItem}
                    onClick={() => handleDetailId(index)} />
                )}
            </SlideScroller>
        </Container>
    </Box>);
};

export default PastExperienceSection;
