import React from 'react';
import { Flex } from '@chakra-ui/react';

import { Card } from 'components/layout';
import { BigDetailIcon, ServiceInfoLabel } from '.';

const ChipPanel = ({ maxW, ...rest }) => {
    return (<Card
        w="full"
        p={0}
        variant="unstyled">
        <Flex
            flexDirection="row"
            align="start"
            gap={6}
            mb={3}
            w="full">
            <BigDetailIcon {...rest} maxW="20vw" iconSize={3.5} mt={4} />
            <Flex flexDirection="column" alignSelf="center">
                <ServiceInfoLabel {...rest} />
            </Flex>
        </Flex>
    </Card>);
};

export default ChipPanel;
