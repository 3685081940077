import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Text, Button } from '@chakra-ui/react';

const DetailFooter = ({ onClose }) => {
    const { t } = useTranslation();
    return (<>
        <Text variant="muted" m={0}>{t('detail_footer_body')}</Text>
        <Button margin={0} ms={3} onClick={onClose}>{t('detail_footer_button_ok')}</Button>
    </>);
}

export default DetailFooter;
