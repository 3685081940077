import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Link as GatsbyLink } from 'gatsby';

import { Box, Link as ChakraLink } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

/**
 * Custom link component to wrap a combination of Links from Chakra UI and
 * 'gatsby-plugin-react-i18next'.
 * 
 * Also hides some underlying issues with the Link component from 'gatsby-plugin-react-i18next'
 * and adds handy features like an "external" icon for urls that point to something that's not
 * on this website.
 * 
 * @param {String} to The URL to visit after clicking the link.
 */
const Link = React.forwardRef(({ to, onClick, isExternal, children, ...rest }, ref) => {
    const LANGUAGE_KEY = 'gatsby-i18next-language';
    const { language } = useI18next();

    if (!to && !onClick) {
        return <Box {...rest}>
            Invalid link '{to}' for '{children}'
        </Box>;
    }

    let normalizedLink = to;
    const isExternalLink = !onClick &&
        !normalizedLink?.startsWith('/') &&
        !normalizedLink?.startsWith('#');

    // The check here is to allow for external behaviour without external link
    // icon. Please don't change the order of this check.
    const fullyExternal = isExternal || isExternalLink;

    const handleClick = (e) => {
        if (language) {
            localStorage.setItem(LANGUAGE_KEY, language);
        }
        if (onClick) {
            onClick(e);
        }
    };

    /**
     * With the `gatsby-plugin-react-i18next` library we should be using its provided
     * Link component here. However, it does very weird things to urls when it's used
     * with a specific set of plugin options that we need to make localised MDX pages
     * usable in a structured way (i.e. save them in dirs).
     * 
     * For that reason, localised urls are built here instead of letting the library
     * handle it.
     */

    // Add current language for automatic i18n support - specifically check for hash
    // to keep path names (e.g. see this in MDX heading links).
    if (!isExternalLink && !normalizedLink?.startsWith('#')) {
        normalizedLink = `/${language}${normalizedLink}`;
    }

    return (
        <ChakraLink
            ref={ref}
            // Gatsby doesn't support external links and wants the developer to use
            // 'a' tags for that. Due to that fact, the Gatsby specific Link component
            // props are only added to ChakraLink (which expands into an 'a' tag) when
            // link is internal.
            //
            // The 'to' prop gets `undefined` to prevent linting errors.
            as={(!isExternal && !isExternalLink) && GatsbyLink}
            to={(!isExternal && !isExternalLink) ? normalizedLink : undefined}
            href={(isExternal || isExternalLink) && normalizedLink}
            isExternal={fullyExternal}
            rel={fullyExternal ? 'nofollow noopener noreferrer' : 'noopener'}
            pointerEvents="all"
            hrefLang={language}
            onClick={(e) => handleClick(e)}
            {...rest}>
            {children}{isExternalLink && !normalizedLink?.startsWith('mailto') && <ExternalLinkIcon ms={1} mb={1} />}
        </ChakraLink>
    );
});

export default Link;
