
/**
 * Lets the user of this function know if we're currently performing server-side
 * rendering or if it's on the browser side.
 * 
 * Can be used to detect if certain rendering steps should be skipped (for example).
 */
export const isSsr = () => typeof window === 'undefined';

/**
 * Composes an URL to the internal Netlify serverless function path.
 * 
 * @param {String} funcEndpointName Netlify function endpoint name. Basically, the name of the .js file.
 * @returns string with full URL to the requested endpoint.
 */
export const getServerlessFuncEndpoint = (funcEndpointName) => `/.netlify/functions/${funcEndpointName}`;
