import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { Stack, Center, Button } from '@chakra-ui/react';
import { List, ListItem, ListIcon, useColorModeValue } from '@chakra-ui/react';

import { Icon } from 'components/view';
import { withTrackEvent } from 'utils/hoc/tracking';

const TrackedButton = (props) => {
    return withTrackEvent(Button, {
        trackedName: props.trackedName,
        trackedValue: props.trackedValue,
        ...props,
    });
};

const PlanColumnListItem = ({ id, name, title, color }) => {
    return (
        <ListItem key={id} color={useColorModeValue('gray.400', 'whiteAlpha.700')} fontSize="sm">
            <ListIcon as={Icon} name={name} size="xs" mb={0.5} color={color} />
            {title}
        </ListItem>
    );
};

const PlanBottomArea = React.memo(({ id, feature, onChooseClick }) => {
    const { t } = useI18next();

    const itemIconColorAvailable = useColorModeValue('primary.300', 'white');
    const itemIconColorUnavailable = useColorModeValue('gray.100', 'primary.300');

    const planItemsActive = feature
        .filter(item => item.active)
        .map((item, index) => (
            <PlanColumnListItem
                key={index}
                id={index}
                name="bx-check"
                title={item.description}
                color={itemIconColorAvailable} />));

    const planItemsInactive = feature
        .filter(item => !item.active)
        .map((item, index) => (
            <PlanColumnListItem
                key={index}
                id={index}
                name="bx-x"
                title={item.description}
                color={itemIconColorUnavailable} />));

    return (<Stack
        direction="column"
        spacing={6}
        px={6}
        pb={10}>
        <List
            spacing={3}
            p={0}>
            {planItemsActive}
            {planItemsInactive}
        </List>
        <Center>
            <TrackedButton
                trackedName="planItem"
                trackedValue={id}
                variant="base-dark"
                onClick={onChooseClick}
                boxShadow="md">
                {t('product_pricing_plan_button_choose')}
            </TrackedButton>
        </Center>
    </Stack>);
});

export default PlanBottomArea;
