import { mode, transparentize } from '@chakra-ui/theme-tools';

const rootStyle = (props) => {
    // Don't repeat for multiple selectors
    const selectionStyle = {
        background: mode(
            transparentize('cyan.300', 0.6),
            transparentize('primary.300', 0.6),
        )(props),
        color: mode('white', 'whiteAlpha.900')(props),
    };

    return {
        '::selection': selectionStyle,
        '::-moz-selection': selectionStyle,
    }
};

const headingBaseStyle = (props) => {
    return {
        fontFamily: `'M PLUS Rounded 1c', arial, helvetica, sans-serif`,
        fontWeight: 'semibold',
        color: mode('secondary.400', 'white')(props),
    };
};

/**
 * See: https://chakra-ui.com/docs/styled-system/theming/component-style
 */
const styles = {
    global: (props) => ({
        ...rootStyle(props),
        html: {
            /**
             * Need to apply this to a containing element (but not Container, because
             * that's used *inside* sections).
             *
             * Does not work on body: https://drafts.csswg.org/cssom-view/#smooth-scrolling
             */
            scrollBehavior: 'smooth',
        },
        body: {
            color: mode('gray.600', 'whiteAlpha.600')(props),
            bg: mode('white', 'gray.800')(props),
            lineHeight: 'tall',
        },
        a: {
            color: 'primary.300',
            transition: 'color .4s ease-in-out',
            // Following block + 'display = flex' in headings is required to remove this margin
            // hack. Should create a custom copy of 'gatsby-remark-autolink-headers' and fix it in there.
            // display: 'flex',
            // flexDirection: 'column',
            // alignSelf: 'center',
            svg: {
                paddingTop: '.15rem', // hack - see comment above
                display: 'inline-block',
                color: mode('gray.100', 'whiteAlpha.300')(props),
            },
            _hover: {
                transition: 'color .4s ease-in-out',
            }
        },
        p: {
            marginBottom: '1.25rem',
        },
        img: {
            // Doesn't look like `crisp-edges` (which should auto-translate to custom attribs for
            // Firefox, Opera and Webkit) works anymore; why? Settle with Webkit support for now.
            imageRendering: '-webkit-optimize-contrast',
            msInterpolationMode: 'nearest-neighbor',
        },
        h1: {
            ...headingBaseStyle(props),
            fontWeight: 'bold',
            fontSize: { base: '2xl', lg: '4xl' },
            marginBottom: '.5rem',
            marginTop: '.75rem',
        },
        h2: {
            ...headingBaseStyle(props),
            fontSize: { base: 'xl', lg: '3xl' },
            fontWeight: 'thin',
            color: mode('gray.800', 'whiteAlpha.800')(props),
            marginBottom: '.5rem',
            marginTop: '.75rem',
        },
        h3: {
            ...headingBaseStyle(props),
            fontSize: { base: 'lg', lg: '2xl' },
            color: mode('gray.800', 'white')(props),
            marginBottom: '.5rem',
            marginTop: '.75rem',
        },
        h4: {
            ...headingBaseStyle(props),
            fontSize: { base: 'lg', lg: 'xl' },
            color: mode('gray.800', 'white')(props),
            marginBottom: '.5rem',
            marginTop: '.75rem',
        },
        h5: {
            ...headingBaseStyle(props),
            fontSize: { base: 'md', lg: 'lg' },
            color: mode('gray.800', 'white')(props),
        },
        h6: {
            ...headingBaseStyle(props),
            fontSize: { base: 'md', lg: 'md' },
            color: mode('gray.800', 'white')(props),
        },
    })
};

export default styles;
