import React from 'react';
import PropTypes from 'prop-types';

import { merge } from 'lodash';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Image } from '@chakra-ui/react';

import { FeatureSection, FeatureComparison, HeroSection, FaqSection, PricingSection, SubscribeSection } from 'components/section/product';
import { DefaultLayout, ForceRatio } from 'components/layout';
import { MetaData } from 'components/meta';

/**
 * Product page for showing specific information and details about
 * a current or upcoming product.
 */
const Product = ({ children, location, data, pageContext }) => {
    const { frontmatter } = pageContext;
    const frontmatterCombined = merge(frontmatter, data.mdx.frontmatter);
    const { main, comparison, feature, usp, faq, plans } = frontmatterCombined;
    const { navigate, language } = useI18next();

    const featureSectionId = 'product-features';
    const faqSectionId = 'frequent-questions';
    const pricingSectionId = 'product-pricing';
    const subscribeSectionId = 'subscribe-list';

    // Partially hardcoded, but added like this to allow future extensions
    let DemoComponent = () => <div />;
    if (comparison) {
        const { gatsbyImageData: imageBefore } = comparison.imageBefore.childImageSharp;
        const { gatsbyImageData: imageAfter } = comparison.imageAfter.childImageSharp;

        const aspectRatio = imageBefore.width / imageBefore.height;
        DemoComponent = () => <ForceRatio maxW="full" ratio={aspectRatio}>
            <FeatureComparison
                imageBefore={<Image as={GatsbyImage} alt="Image Before" image={getImage(imageBefore)} ratio={aspectRatio} />}
                imageAfter={<Image as={GatsbyImage} alt="Image After" image={getImage(imageAfter)} ratio={aspectRatio} />}
            />
        </ForceRatio>;
    }

    const featureSectionProps = {
        items: usp,
        heading: feature?.title,
        description: feature?.description,
        demoText: comparison?.description,
        demoComponent: <DemoComponent />,
    };

    const handlePlanClick = (planId) => {
        navigate(`${location.pathname.replace(`/${language}`, '')}#${subscribeSectionId}`);
    };

    return (<>
        <MetaData location={location} />
        <DefaultLayout>
            <HeroSection    
                toLink={`#${featureSectionId}`}
                image={frontmatterCombined.hero_image}
                {...main} />
            <FeatureSection
                id={featureSectionId}
                {...featureSectionProps} />
            <FaqSection
                id={faqSectionId}
                items={faq} />
            <PricingSection
                id={pricingSectionId}
                onPlanClick={handlePlanClick}
                plans={plans} />
            {children}
            <SubscribeSection
                id={subscribeSectionId}
                fromPath={location.pathname} />
        </DefaultLayout>
    </>);
};

Product.propTypes = {
    children: PropTypes.node,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object.isRequired,
};

export default Product;
