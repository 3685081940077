import React from 'react';
import { Flex } from '@chakra-ui/react';

import { Card } from 'components/layout';
import { BigDetailIcon, ServiceInfoLabel } from '.';

const SidePanel = ({ maxW, children, ...rest }) => {
    return (<Card
        w="sm"
        p={6}
        variant="unstyled">
        <Flex
            flexDirection="column"
            alignSelf="center"
            maxW={maxW}
            w="full"
            m={3}>
            <BigDetailIcon {...rest} maxW="18em" iconSize={16} hasOutline={true}/>
            <ServiceInfoLabel {...rest} />
            {children}
        </Flex>
    </Card>);
};

export default SidePanel;
