import React from 'react';
import { Container, Box, Flex, IconButton, Stack, useColorModeValue } from '@chakra-ui/react';

import { Link } from 'components/navigation';
import { Icon as BxIcon } from 'components/view';
import { NavigationMenuItem, LanguageToggle, ThemeToggle } from 'components/navigation';

import { NavigationMenuStack } from './NavigationMenuStack';

const NavigationBar = ({ items, location, ...rest }) => {
    // NOTE: zIndex should be 'sticky', but it clashes with Modal / DrawerOverlay
    //       because the navbar would be drawn on top of those.
    const barBackgroundColorDesktop = useColorModeValue('white', 'gray.800');
    return (<Box
        pos="fixed"
        top="0"
        // zIndex="sticky"
        zIndex={40}
        width="full"
        bg={barBackgroundColorDesktop}
        {...rest}>
        <Container
            maxW="container.xl"
            color={useColorModeValue('gray.600', 'white')}>
            <Flex
                width="full"
                minH="3.5rem"
                align="center"
                justify="space-between"
                position="relative">
                <Flex>
                    <IconButton
                        as={Link}
                        to="/"
                        icon={<BxIcon name="bx-home-alt" size="xs" />}
                        variant="navlink"
                        aria-label="Toggle Theme" />

                    <Flex ml={10}>
                        <Stack direction="row" spacing={4}>
                            {items?.map((navMenu, id) => (
                                <NavigationMenuItem
                                    key={id}
                                    label={navMenu.label}
                                    showDownIcon={true}>
                                    <NavigationMenuStack subMenuItems={navMenu.items} />
                                </NavigationMenuItem>
                            ))}
                        </Stack>
                    </Flex>
                </Flex>

                <Stack
                    // flex={{ base: 1, md: 0 }}
                    justify="flex-end"
                    direction="row"
                    spacing={3}>
                    <LanguageToggle />
                    <ThemeToggle variant="ghost" />
                </Stack>
            </Flex>
        </Container>
    </Box>);
}

export default NavigationBar;
