import React, { Children } from 'react';
import { DripBackground, Logo as LogoView } from 'components/view';

/**
 * Displays the main site hero image.
 */
const SiteHero = ({ children, ...rest }) => {
    // DripBackground below needs display=flex - keep this in mind
    const orientation = 'horizontal';
    const childArray = Children.toArray(children);

    return <DripBackground
        orientation={orientation}
        {...rest}>
        {childArray.length > 0 ?
            children :
            <LogoView orientation={orientation} />}
    </DripBackground>;
};

export default SiteHero;
