import React, { Children } from 'react';
import { chakra, Container, Box, Flex } from '@chakra-ui/react';

import { Card } from 'components/layout';
import { withMdxProvider, defaultMdxComponents } from 'utils/hoc/mdx';

const TocSection = ({ children }) => {
    const childDivToC = Children.toArray(children).filter(child => child.props?.className === 'table-of-contents');
    const hasTableOfContents = childDivToC.length !== 0;

    const childArray = Children
        .toArray(children)
        .filter(child => child.props?.id !== 'table-of-contents')
        .filter(child => child.props?.className !== 'table-of-contents');

    return (<Flex>
        <Container
            as="section"
            maxW={hasTableOfContents ? 'container.xl' : 'container.lg'}
            my={3}>
            <Flex
                direction={{ base: 'column', md: 'row' }}
                gap={{ base: 0, md: 12 }}
                my={6}>
                <Box
                    as="article"
                    direction="row"
                    order={{ base: 2, md: 1 }}>
                    <Box display={{ base: 'none', md: 'block' }}>{childArray}</Box>
                    <Box display={{ base: 'block', md: 'none' }}>{Children.toArray(children)}</Box>
                </Box>
                {/* <Spacer /> */}
                {hasTableOfContents && <Box
                    display={{ base: 'none', md: 'block' }}
                    order={{ base: 1, md: 2 }}>
                    <Box
                        as="aside"
                        pos="sticky"
                        top={0}
                        w="sm"
                        mt={{ base: 0, md: -24 }}
                        pt={{ base: 0, md: 24 }}>
                        <Card variant="nostyle" align="start">
                            <chakra.h3>Table of Contents</chakra.h3>
                            {childDivToC}
                        </Card>
                    </Box>
                </Box>}
                {/* Box to add empty space on the left */}
                {<Box maxW={32} />}
                {/* {<Box minW={1} rounded="lg" bgColor="secondary.300" />} */}
            </Flex>
        </Container>
    </Flex>)
};

export default withMdxProvider(TocSection, defaultMdxComponents);
