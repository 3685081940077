import React from 'react';
import PropTypes from 'prop-types';

import { Box, useBreakpointValue } from '@chakra-ui/react';

/**
 * Declares an icon from the freely available 'Box Icons' set.
 */
const Icon = React.forwardRef(({ name, size, fontSize, color, className, ...rest }, ref) => {
    // In the future it would be better to stop using `bx-{size}` here and switch to Chakra UI sizes or `transform: scale(n)`
    if (!fontSize) fontSize = {};
    const fontSizeBreakpoint = useBreakpointValue(fontSize, { ssr: false });

    // Show *something* when no icon name is passed
    if (!name) {
        name = 'bx-info-circle';
    }

    // Usage of `className` property exists because Chakra UI passes it down when
    // this `Icon` component is used indirectly through one of the Chakra components.
    let innerClassName = `bx ${name}`;

    if (size) innerClassName += ` bx-${size}`;
    if (className) innerClassName += ` ${className}`;

    return (
        <Box
            ref={ref}
            sx={{ color }}
            fontSize={fontSize && fontSizeBreakpoint}
            className={innerClassName}
            {...rest}
        />
    );
});

Icon.propTypes = {
    // Name of the icon, such as `bx-water`.
    name: PropTypes.string,
    // Size in units `xs`, `sm`, `md` and `lg`.
    size: PropTypes.string,
    // Hexadecimal value or CSS variable name.
    color: PropTypes.string,
}

export default Icon;
