import React from 'react';
import { chakra } from '@chakra-ui/react';

import { VectorBackground, SiteHero } from 'components/view';

const HeaderMobile = () =>
    <VectorBackground
        display={{
            base: 'block',
            md: 'none'
        }}
        height={1}
        pattern="plaid"
        scale={1}
        colors={[
            'secondary.300',
            '#fab369',
        ]} />;

const HeaderDesktop = () =>
    <SiteHero
        display={{
            base: 'none',
            md: 'block'
        }}
        pt={12}>
        <chakra.h1 m={0} p={0}></chakra.h1>
    </SiteHero>;

const PageHeader = ({ title, description }) => {
    return (<>
        <HeaderMobile />
        <HeaderDesktop />
    </>);
};

export default PageHeader;
