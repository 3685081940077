import React from 'react';
import { Wrap, WrapItem, Tag, useColorModeValue, Box } from '@chakra-ui/react';

const ServiceInfoLabel = ({ title, tags }) => {
    const tagBackgroundColor = useColorModeValue('gray.25', 'blackAlpha.300');
    const tagColor = useColorModeValue('gray.300', 'whiteAlpha.700');

    return (<>
        <Box
            layerStyle="display-1"
            textStyle="display-1">
            {title}
        </Box>
        <Wrap alignItems="start" justify="start" gap={1.5}>
            {tags && tags.map((singleTag, id) => <WrapItem key={id}>
                <Tag
                    size="md"
                    bg={tagBackgroundColor}
                    color={tagColor}>
                    {singleTag}
                </Tag>
            </WrapItem>)}
        </Wrap>
    </>);
};

export default ServiceInfoLabel;
